import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.primary1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
export const MainHeading = styled.div`
  font-size: 38px;
  opacity: 0.7;
  width: 35rem;
  margin: 0rem;
  font-family: Chillax-medium !important;
  font-weight: 400;
  width: 55%;
  padding: 4rem 5rem 4rem 0rem;
  margin-bottom: 9rem;
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 30px;
  width: 50%;
  margin: 0rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 30px;
  width: 80%;
  margin: 0rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin: 4rem 1rem 0rem 3rem;
  width: 100%;
  `};
`
