import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH } from '@jessupwall02/sdk'
import { useMemo } from 'react'
import {
  BIG_INT_ONE,
  BIG_INT_ZERO,
  DAI,
  DMOD,
  DMOD_DMOD_LP,
  DMOD_MCK_LP,
  USDC,
  DMOD_GLMR_LP,
  DMODToken,
  DMOD_USDT_LP,
  DMOD_USDC_LP,
  USDT
} from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData } from '../multicall/hooks'
// import { useStakingContract, useYieldFarmContract } from 'hooks/useContract'
import dmod from '../../assets/images/dmodround.png'
import moonbeam from '../../assets/images/moonbeam.png'
import usdt from '../../assets/images/usdt.png'
import usdc from '../../assets/images/usdc.png'
import { usePrice } from './useAllPoolInfo'
import { IUniswapV2PairABI_INTERFACE, YIELDFARM_INTERFACE } from '../../constants/abis/staking-rewards'

export const STAKING_GENESIS = 1600387200

export const REWARDS_DURATION_DAYS = 60

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [WETH[ChainId.MAINNET], DAI],
      stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711'
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b'
    }
    // {
    //   tokens: [WETH[ChainId.MAINNET], USDT],
    //   stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a'
    // },
    // {
    //   tokens: [WETH[ChainId.MAINNET], WBTC],
    //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e'
    // }
  ],
  [ChainId.BSCTEST]: [
    {
      tokens: [WETH[ChainId.MAINNET], DAI],
      stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711'
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b'
    }
    // {
    //   tokens: [WETH[ChainId.MAINNET], USDT],
    //   stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a'
    // },
    // {
    //   tokens: [WETH[ChainId.MAINNET], WBTC],
    //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e'
    // }
  ]
}

// TODO add staking rewards addresses here
export const STAKING_CONTRACTS_INFO: {
  [chainId in ChainId]?: {
    staking: string
    communityVault: string
    farms: {
      name: string
      token: Token
      tokenA: Token
      tokenB: Token
      yieldFarm: string
      GENESIS_EPOCH_AMOUNT_DMOD: number
      deprecationPerEpochDMOD: number
      logoOne: any
      logoTwo: any
      category: string
      tokenACGO: string
      tokenBCGO: string
    }[]
  }[]
} = {
  [ChainId.BSCTEST]: [
    {
      staking: '0x9b934c87C7b7bbA45424e94da66016125798a956',
      communityVault: '0xac1Ca9164A0Ab3D6e596C39124678489F555a585',
      farms: [
        {
          name: 'DMOD-GLMR',
          token: DMOD_MCK_LP,
          tokenA: DMOD,
          tokenB: DMOD,
          yieldFarm: '0x8A1cB1568083591DC2460C1f537d6a28eD398565',
          GENESIS_EPOCH_AMOUNT_DMOD: 20000,
          deprecationPerEpochDMOD: 1,
          logoOne: dmod,
          logoTwo: moonbeam,
          category: 'dmod',
          tokenACGO: 'moonbeam',
          tokenBCGO: 'demodyfi'
        },
        {
          name: 'DMOD-USDC',
          token: DMOD_DMOD_LP,
          tokenA: DMOD,
          tokenB: DMOD,
          yieldFarm: '0x83eEaDB0327bc21Dc88a2050Fc969aC274170301',
          GENESIS_EPOCH_AMOUNT_DMOD: 20000,
          deprecationPerEpochDMOD: 1,
          logoOne: dmod,
          logoTwo: moonbeam,
          category: 'stable',
          tokenACGO: 'usd-coin',
          tokenBCGO: 'demodyfi'
        }
        // {
        //   token: DMODETHLP,
        //   yieldFarm: '0xde3843D3d9009039790575F34A29acC6eC535642',
        //   GENESIS_EPOCH_AMOUNT_DMOD: 20000,
        //   deprecationPerEpochDMOD: 125
        // }
      ]
    }
  ],
  [ChainId.MAINNET]: [
    {
      staking: '0xBc3165c2d2B49851985EFC9d12Db4a906E11dC34',
      communityVault: '0x2F63a712adeA24BcB78e717F5959C4cd2dBd0C4C',
      farms: [
        {
          name: 'DMOD-GLMR',
          token: DMOD_GLMR_LP,
          tokenA: WETH[ChainId.BSCTEST],
          tokenB: DMODToken[ChainId.MAINNET],
          yieldFarm: '0x717b95a0Db06Fbe6d46992Cb930Ac03af988e8B2',
          GENESIS_EPOCH_AMOUNT_DMOD: 4033,
          deprecationPerEpochDMOD: 1,
          logoOne: dmod,
          logoTwo: moonbeam,
          category: 'dmod',
          tokenACGO: 'moonbeam',
          tokenBCGO: 'demodyfi'
        },
        {
          name: 'DMOD-USDC',
          token: DMOD_USDC_LP,
          tokenA: USDC,
          tokenB: DMODToken[ChainId.MAINNET],
          yieldFarm: '0xE36816c82D4eB9270e68231eCd3B470b0f2Ae5B0',
          GENESIS_EPOCH_AMOUNT_DMOD: 1615,
          deprecationPerEpochDMOD: 1,
          logoOne: dmod,
          logoTwo: usdc,
          category: 'dmod',
          tokenACGO: 'moonbeam',
          tokenBCGO: 'demodyfi'
        },
        {
          name: 'DMOD-USDT',
          token: DMOD_USDT_LP,
          tokenA: USDT,
          tokenB: DMODToken[ChainId.MAINNET],
          yieldFarm: '0xfA9b4b2Ddc1D1748aaA5BFB677E8e70e791dB3aB',
          GENESIS_EPOCH_AMOUNT_DMOD: 650,
          deprecationPerEpochDMOD: 1,
          logoOne: dmod,
          logoTwo: usdt,
          category: 'dmod',
          tokenACGO: 'moonbeam',
          tokenBCGO: 'demodyfi'
        }
        // {
        //   token: DMODETHLP,
        //   yieldFarm: '0xde3843D3d9009039790575F34A29acC6eC535642',
        //   GENESIS_EPOCH_AMOUNT_DMOD: 20000,
        //   deprecationPerEpochDMOD: 125
        // }
      ]
    }
  ]
}

// export interface StakingInfo {
//   // the address of the reward contract
//   stakingRewardAddress: string
//   // the tokens involved in this pair
//   tokens: [Token, Token]
//   // the amount of token currently staked, or undefined if no account
//   stakedAmount: TokenAmount
//   // the amount of reward token earned by the active account, or undefined if no account
//   earnedAmount: TokenAmount
//   // the total amount of token staked in the contract
//   totalStakedAmount: TokenAmount
//   // the amount of token distributed per second to all LPs, constant
//   totalRewardRate: TokenAmount
//   // the current amount of token distributed to the active account per second.
//   // equivalent to percent of total supply * reward rate
//   rewardRate: TokenAmount
//   // when the period ends
//   periodFinish: Date | undefined
//   // if pool is active
//   active: boolean
//   // calculates a hypothetical amount of token distributed to the active account per second.
//   getHypotheticalRewardRate: (
//     stakedAmount: TokenAmount,
//     totalStakedAmount: TokenAmount,
//     totalRewardRate: TokenAmount
//   ) => TokenAmount
// }

// gets the staking info from the network for the active chain id
export function useStakingPoolsInfo(): any[] {
  const { chainId, account } = useActiveWeb3React()
  const glmrPrice = usePrice('moonbeam')

  const dmodPrice = usePrice('demodyfi')

  const usdtPrice = usePrice('tether')

  const usdcPrice = usePrice('usd-coin')

  // detect if staking is ended
  // const stakingContractInstance = useStakingContract()
  // const yieldFarmContractInstance = useYieldFarmContract()

  const info1 = useMemo(() => (chainId ? STAKING_CONTRACTS_INFO[chainId] ?? [] : []), [chainId])

  // const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const yieldFarmAddresses = useMemo(() => info1[0]?.farms.map(({ yieldFarm }) => yieldFarm), [info1])
  const lpTokens = useMemo(() => info1[0]?.farms.map(({ token }) => token.address), [info1])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const currentEpochs = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getCurrentEpoch')

  const currentEpochsNext = useMemo(() => {
    return currentEpochs?.[0]?.result?.[0]
      ? JSBI.add(JSBI.BigInt(currentEpochs?.[0]?.result?.[0]), BIG_INT_ONE) ?? undefined
      : undefined
  }, [currentEpochs])

  const NR_OF_EPOCHS = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'NR_OF_EPOCHS')

  const TOTAL_DISTRIBUTED_AMOUNTS = useMultipleContractSingleData(
    yieldFarmAddresses,
    YIELDFARM_INTERFACE,
    'TOTAL_DISTRIBUTED_AMOUNT'
  )

  const poolBalances = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getPoolSize', [
    currentEpochsNext?.toString()
  ])

  const epochStakeNexts = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getEpochStake', [
    accountArg?.[0],
    currentEpochsNext?.toString()
  ])
  const reserves = useMultipleContractSingleData(lpTokens, IUniswapV2PairABI_INTERFACE, 'getReserves')
  const totalSupplies = useMultipleContractSingleData(lpTokens, IUniswapV2PairABI_INTERFACE, 'totalSupply')

  return useMemo(() => {
    if (!chainId) return []
    if (!yieldFarmAddresses) {
      return []
    }

    return info1[0]?.farms.reduce<any[]>((memo, yieldFarm, index) => {
      // return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account

      // these get fetched regardless of account
      const currentEpoch = currentEpochs[index]

      const poolBalance = poolBalances[index]

      const NR_OF_EPOCH = NR_OF_EPOCHS[index]

      const TOTAL_DISTRIBUTED_AMOUNT = TOTAL_DISTRIBUTED_AMOUNTS[index]

      const epochStakeNext = epochStakeNexts[index]

      const reserve = reserves[index]
      const totalSupply = totalSupplies[index]

      if (
        // these may be undefined if not logged in
        currentEpoch &&
        !currentEpoch?.loading &&
        poolBalance &&
        !poolBalance?.loading &&
        NR_OF_EPOCH &&
        !NR_OF_EPOCH?.loading &&
        TOTAL_DISTRIBUTED_AMOUNT &&
        !TOTAL_DISTRIBUTED_AMOUNT?.loading &&
        reserve &&
        !reserve?.loading &&
        totalSupply &&
        !totalSupply?.loading &&
        !epochStakeNext?.loading

        // always need these
      ) {
        if (
          currentEpoch?.error ||
          poolBalance?.error ||
          NR_OF_EPOCH?.error ||
          reserve?.error ||
          totalSupply?.error ||
          TOTAL_DISTRIBUTED_AMOUNT?.error ||
          epochStakeNext?.error
        ) {
          // console.error('Failed to load staking rewards info')
          return memo
        }

        const reserveA = new TokenAmount(yieldFarm.tokenA, reserve?.result?.[0])
        const reserveB = new TokenAmount(yieldFarm.tokenB, reserve?.result?.[1])
        const supplyAmount = new TokenAmount(yieldFarm.token, totalSupply?.result?.[0])

        let tokenAPrice

        if (yieldFarm.tokenA.symbol === 'USDT') {
          tokenAPrice = usdtPrice
        } else if (yieldFarm.tokenA.symbol === 'USDC') {
          tokenAPrice = usdcPrice
        } else {
          tokenAPrice = glmrPrice
        }

        let tokenBPrice = dmodPrice

        const lpPrice =
          (parseFloat(reserveA.toFixed(6)) * tokenAPrice + parseFloat(reserveB.toFixed(6)) * tokenBPrice) /
          parseFloat(supplyAmount.toFixed(6))

        const ratio = lpPrice / dmodPrice

        const calcTotalAmountPerEpoch = (genesisEpochAmount: JSBI, epochId: JSBI, deprecationPerEpoch: JSBI) => {
          return JSBI.toNumber(epochId) === 0
            ? BIG_INT_ZERO
            : JSBI.subtract(genesisEpochAmount, JSBI.multiply(epochId, deprecationPerEpoch))
        }

        const rewardForCurrentEpochCalculated = calcTotalAmountPerEpoch(
          JSBI.BigInt(info1[0]?.farms[index].GENESIS_EPOCH_AMOUNT_DMOD),
          JSBI.BigInt(currentEpochs?.[0]?.result?.[0]),
          JSBI.BigInt(info1[0]?.farms[index].deprecationPerEpochDMOD)
        )

        let annualReward = BIG_INT_ZERO

        for (
          var i = currentEpochs?.[0]?.result?.[0].toNumber();
          i <= currentEpochs?.[0]?.result?.[0].toNumber() + 372;
          i++
        ) {
          const epochAmount = calcTotalAmountPerEpoch(
            JSBI.BigInt(info1[0]?.farms[index].GENESIS_EPOCH_AMOUNT_DMOD),
            JSBI.BigInt(i),
            JSBI.BigInt(info1[0]?.farms[index].deprecationPerEpochDMOD)
          )

          annualReward = JSBI.add(annualReward, epochAmount)
        }

        const poolBalanceNumber = new TokenAmount(info1[0]?.farms[index].token, poolBalance?.result?.[0]?.toString())

        const apr = (JSBI.toNumber(annualReward) / (parseFloat(poolBalanceNumber.toFixed(5)) * ratio)) * 100

        const TOTAL_DISTRIBUTED_AMOUNT_CURRENCY = CurrencyAmount.ether(TOTAL_DISTRIBUTED_AMOUNT.result?.[0])

        const epochStakeNextCurrency = CurrencyAmount.ether(epochStakeNext?.result?.[0] ?? BIG_INT_ZERO)
        const poolBalanceCurrency = CurrencyAmount.ether(poolBalance?.result?.[0])

        // const rewardForCurrentEpoch = CurrencyAmount.ether(rewardForCurrentEpochCalculated) ?? undefined

        memo.push({
          name: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].name,
          logoOne: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].logoOne,
          logoTwo: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].logoTwo,
          token: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].token,
          staking: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.staking,
          yieldFarm: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].yieldFarm,
          GENESIS_EPOCH_AMOUNT_DMOD: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].GENESIS_EPOCH_AMOUNT_DMOD,
          deprecationPerEpochDMOD: STAKING_CONTRACTS_INFO?.[chainId]?.[0]?.farms[index].deprecationPerEpochDMOD,
          currentEpoch: currentEpoch.result?.[0].toString(),
          poolBalanceCurrency,
          poolBalanceCurrencyValue: parseFloat(poolBalanceCurrency.toFixed(6)) * lpPrice,
          NR_OF_EPOCH: NR_OF_EPOCH.result?.[0].toString(),
          TOTAL_DISTRIBUTED_AMOUNT_CURRENCY,
          epochStakeNextCurrency,
          rewardForCurrentEpoch: rewardForCurrentEpochCalculated.toString(),
          apr
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    NR_OF_EPOCHS,
    TOTAL_DISTRIBUTED_AMOUNTS,
    currentEpochs,
    epochStakeNexts,
    info1,
    poolBalances,
    yieldFarmAddresses,
    dmodPrice,
    glmrPrice,
    reserves,
    totalSupplies,
    usdcPrice,
    usdtPrice
  ])
}
