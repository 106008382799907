import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background: linear-gradient(342deg, #5902d2 0%, rgba(133, 66, 244, 0) 53.86%),
    radial-gradient(58.26% 110.26% at 0% 103.31%, #19d2ff 26.56%, rgba(16, 231, 251, 0) 110%),
    radial-gradient(67.56% 110.56% at 100% 0%, #ffccfb 0%, #ff39f0 45.31%, rgba(238, 65, 255, 0) 110%),
    radial-gradient(83.68% 83.68% at 0% 0%, #5902d2 0%, rgba(133, 66, 244, 0) 150%);
  margin-top: 1rem;
  border-radius: 100px;
  color: white;
  width: 100%;
  // padding: 0.75rem;
  font-weight: 600;
  font-family: Poppins;
  font-size: 16px;
  height: 52px;
  border: 0px solid;
  text-align: center;
  border-image-source: linear-gradient(
    114.3deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 48.44%,
    rgba(255, 255, 255, 0.4) 100%
  );
  cursor: pointer;

  &:hover {
    background: linear-gradient(342deg, #5902d2 0%, rgba(133, 66, 244, 0) 53.86%),
      radial-gradient(58.26% 110% at 103.31% 0%, #19d2ff 26.56%, rgba(16, 231, 251, 0) 110%),
      radial-gradient(67.56% 110% at 0% 100%, #ffccfb 0%, #ff39f0 45.31%, rgba(238, 65, 255, 0) 110%),
      radial-gradient(83.68% 83.68% at 0% 0%, #5902d2 0%, rgba(133, 66, 244, 0) 150%);
    color: #ffffff;
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonLight = styled(Base)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 16px 34px;
  height: 52px;
  border: 0px;
  cursor: pointer;
  background: #171730;
  border-radius: 100px;
  // margin-top: 2rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'pnum' on, 'lnum' on;
  color: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`
export const ButtonText = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'pnum' on, 'lnum' on;
  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 10px;
`};
`

export const PurpleGradientButtonText = styled.h5`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg4)};
  }
`

export const ButtonSecondary = styled(Base)`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 10px;
  `};
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

// const ButtonConfirmedStyle = styled(Base)`
//   background-color: ${({ theme }) => lighten(0.5, theme.green1)};
//   color: ${({ theme }) => theme.green1};
//   border: 1px solid ${({ theme }) => theme.green1};

//   &:disabled {
//     opacity: 50%;
//     cursor: auto;
//   }
// `

const ButtonErrorStyle = styled(Base)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 34px;
  height: 56px;
  border: 0px;
  cursor: pointer;
  background: #171730;
  border-radius: 100px;
  margin-top: 2rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'pnum' on, 'lnum' on;
  color: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  children,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return (
      <ButtonLight {...rest}>
        <ButtonText>{children}</ButtonText>
      </ButtonLight>
    )
  } else {
    return (
      <ButtonLight {...rest} altDisabledStyle={altDisabledStyle}>
        <ButtonText>{children}</ButtonText>
      </ButtonLight>
    )
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownGrey({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonGray {...rest} disabled={disabled} style={{ borderRadius: '20px' }}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonGray>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
