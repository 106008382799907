import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
// import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Soon from '../components/Soon'
import SoonTwo from '../components/SoonTwo'

// import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
// import { ApplicationModal } from '../state/application/actions'
// import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
// import Trade from '../components/Trade'
// import Manage from './Earn/Manage'
// import MigrateV1 from './MigrateV1'
// import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
// import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'
import Earn from './Earn'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  margin-bottom: 1rem;
  flex: 1;
  margin-top: 1.5rem;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
`
// const Top = styled.div`
// position: absolute;
// width: 187px;
//     height: 111px;
//     left: 0;
//     top: 0;
//     background: #19D2FF;
//     opacity: 0.7;

//     filter: blur(123px);
// transform: matrix(0.87, -0.5, -0.5, -0.87, 0, 0);
// `;
// const Bottom = styled.div`
// position: absolute;
// width: 160px;
//     height: 140px;
//     right: 0;
//     bottom: 0;
//     background: #FF39F0;
//     opacity: 0.6;
//     filter: blur(120px);
// transform: matrix(0.87, -0.5, -0.5, -0.87, 0, 0);
// `;

const BodyWrapper = styled.div`
  display: flex;
  // position: absolute;
  // right: 4rem;
  align-items: right;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  // z-index: 10;
  width: auto;
  // background: rgba(54, 54, 67, 0.23);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // padding-top: 2rem;
    display: block;
    justify-items: center;
  `};

  width: 100%;
  margin-bottom: 2rem;
  padding: 3rem 5rem;
`

const AppDiv = styled.div`
min-height: 100vh;
display: flex;
flex-direction: column;
// ${({ theme }) => theme.mediaWidth.upToSmall`
//    min-width: 175vw;
//    min-height: 175vh;
// // display: none;
//   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
   min-width: 180vw;
   min-height: 180vh;
// display: none;
  `};
`

const Heading = styled.h2`
display: none;
// ${({ theme }) => theme.mediaWidth.upToMedium`
// display: block;
// font-size: 38px;
// opacity: 0.7;
// position: absolute;
// top: 50%;
// left: 50%;
// margin-right: -50%;
// transform: translate(-50%, -50%);
// font-family: 'Chillax' !important;
// font-weight: 400;
// text-align: center;
// background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
// -webkit-background-clip: text;
// -webkit-text-fill-color: transparent;
// `};
`

// const Marginer = styled.div`
//   margin-top: 5rem;
// `

// function TopLevelModals() {
//   const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
//   const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
//   return <AddressClaimModal isOpen={open} onDismiss={toggle} />
// }

export default function App() {
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Heading>Please Use Desktop For Best Experience</Heading>
      <AppDiv>
        {/* <Top />
        <Bottom />  */}
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <AppWrapper>
          <URLWarning />
          <BodyWrapper>
            <Popups />
            {/* <Polling /> */}
            {/* <TopLevelModals /> */}
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                {/* <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} /> */}
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/earn" component={Earn} />
                <Route exact strict path="/soon" component={Soon} />
                <Route exact strict path="/coming-soon" component={SoonTwo} />

                {/* <Route exact strict path="/vote" component={Vote} /> */}
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact path="/create" component={AddLiquidity} />
                <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                {/* <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} /> */}
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                {/* <Route exact strict path="/migrate/v1" component={MigrateV1} /> */}
                {/* <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} /> */}
                {/* <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} /> */}
                {/* <Route exact strict path="/vote/:id" component={VotePage} /> */}
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            {/* <Marginer /> */}
          </BodyWrapper>
          {/* <Marginer /> */}
        </AppWrapper>
        <Footer />
      </AppDiv>
    </Suspense>
  )
}
