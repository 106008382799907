import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import { DataCard } from '../earn/styled'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTwitter, faTelegram, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1.5rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`
const ModalUpper = styled(DataCard)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: transparent;
  border-radius: 0px;
`
const ContentWrapper = styled(AutoColumn)`
  width: 35rem;
`

const Paragraph = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.7rem;
  font-weight: 400;
  font-family: 'Poppins';
`
const FooterRow = styled.div`
  display: flex;
`
const FooterIcon = styled.div`
  color: #999999;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
  background: rgba(48, 41, 72, 0.46);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  &:hover {
    color: #fff;
  }
`
const FooterFontIcon = styled(FontAwesomeIcon)`
  margin: 0.43rem;
  color: #696694;
`
const TimesIcon = styled.div`
  font-size: 20px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
  &:hover {
    color: #fff;
  }
`
export default function AnalyticsModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  // modal and loading

  // wrapped onUserInput to clear signatures

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <ContentWrapper>
        <ModalUpper>
          <TimesIcon onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </TimesIcon>
        </ModalUpper>
        <div style={{ padding: '0rem 2rem 1rem 2rem', textAlign: 'justify' }}>
          <Paragraph>
            The demodyfi dex is currenlty new and we're still in the early stages. Once we have enough data to display
            we're going to launch the Analytics. We will announce the news when it's live on all social platform so keep
            an eye out for the news.
            <br />
            <br />
            Please do reach out to us in any of our social platforms if you have any questions in the meanwhile.
            <br />
            <FooterRow style={{ padding: '1rem 0rem' }}>
              <FooterIcon
                style={{ marginLeft: '0.5rem' }}
                onClick={e => window.open('https://github.com/DemodyFI', '_blank', 'noopener noreferrer')}
              >
                <FooterFontIcon icon={faGithub} />
              </FooterIcon>
              <FooterIcon
                onClick={e =>
                  window.open('https://www.linkedin.com/company/demodyfi/', '_blank', 'noopener noreferrer')
                }
              >
                <FooterFontIcon icon={faLinkedin} />
              </FooterIcon>
              <FooterIcon onClick={e => window.open('https://twitter.com/DemodyFi', '_blank', 'noopener noreferrer')}>
                <FooterFontIcon icon={faTwitter} />
              </FooterIcon>
              <FooterIcon onClick={e => window.open('https://t.me/DemodyFI', '_blank', 'noopener noreferrer')}>
                <FooterFontIcon icon={faTelegram} />
              </FooterIcon>
              <FooterIcon onClick={e => window.open('https://demodyfi.medium.com/', '_blank', 'noopener noreferrer')}>
                <FooterFontIcon icon={faMedium} />
              </FooterIcon>
            </FooterRow>
          </Paragraph>
        </div>
      </ContentWrapper>
    </Modal>
  )
}
