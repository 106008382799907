import React, { useRef } from 'react'
import { BookOpen, Code, Info, MessageCircle, Droplet, Link, CreditCard, Repeat, Aperture, Layers } from 'react-feather'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
// import { useActiveWeb3React } from '../../hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import dmod from '../../assets/images/dmod.png'
import { ExternalLink, TYPE } from '../../theme'
import { Text } from 'rebass'
import { CountUp } from 'use-count-up'
import { useActiveWeb3React } from '../../hooks'
import usePrevious from '../../hooks/usePrevious'
import { useETHBalances } from '../../state/wallet/hooks'
// import { TokenAmount } from '@jessupwall02/sdk'
// import { ButtonPrimary } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { usePrice } from 'state/stake/useAllPoolInfo'
const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 38px;
  width: 38px;
  background-color: rgba(106, 121, 159, 0.18);
  border-radius: 100%;

  :hover,
  :focus {
    opacity: 0.8;
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const DmodImgTablet = styled.img`
  width: 1rem;
  height: 1rem;
  margin-top: 0.5rem;
  margin-right: 0.4rem;
`

const MenuFlyoutTablet = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  min-width: 9.5rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100; 
  `};
`

// const ConnectButtonText = styled.h4`
//   margin-top: 0.4rem;
//   margin-right: 0.5rem;
//   background: linear-gradient(
//     73.6deg,
//     #91dcf0 6.19%,
//     #25b0d7 25.05%,
//     #fa8ffa 57.68%
//   );
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//   margin-top: 0.2rem;
//   `};
// `;

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  flex: 1;
  padding: 0.5rem 0.5rem;
  text-decoration: none;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`
const HideSmall = styled.span``
const BalanceText = styled(Text)`
  // ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  //   display: none;
  // `};
`
const AccountElement = styled.div<{ active: boolean }>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`
const Divider = styled.hr`
  background: #1f1f1f;
  width: 100%;
`
export default function Menu() {
  // const { account } = useActiveWeb3React()
  const { account } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const dmodPrice = usePrice('demodyfi')

  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  // const dmodBalance: TokenAmount | undefined = useDMODBalance()
  const countUpValue = dmodPrice?.toFixed(3) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <>
          <MenuFlyout>
            <MenuItem id="link" href="https://demodyfi.com">
              <Info size={14} />
              About
            </MenuItem>
            <MenuItem id="link" href="https://docs.demodyfi.com">
              <BookOpen size={14} />
              Docs
            </MenuItem>
            <MenuItem id="link" href={'https://github.com/Demodyfi'}>
              <Code size={14} />
              Code
            </MenuItem>
            <MenuItem id="link" href="https://discord.com/invite/zWDyzKQFH9">
              <MessageCircle size={14} />
              Discord
            </MenuItem>
            {/* <MenuItem id="link" href="https://uniswap.info/">
            <PieChart size={14} />
            Analytics
          </MenuItem> */}
            {/* {account && (
            <ButtonPrimary onClick={openClaimModal} padding="8px 16px" width="100%" borderRadius="12px" mt="0.5rem">
              Claim UNI
            </ButtonPrimary>
          )} */}
          </MenuFlyout>
          <MenuFlyoutTablet>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto', display: 'flex' }}>
              {account && userEthBalance ? (
                <>
                  <FontAwesomeIcon color="#53F3C3" style={{ width: '0.5rem', marginLeft: '0.5rem' }} icon={faCircle} />
                  <BalanceText style={{ flexShrink: 0 }} pl="0.4rem" pr="0.5rem" fontWeight={500}>
                    {userEthBalance?.toSignificant(4)} GLMR
                  </BalanceText>
                </>
              ) : null}
            </AccountElement>
            <AccountElement active={true} style={{ pointerEvents: 'auto', display: 'flex', marginTop: '0rem' }}>
              <DmodImgTablet src={dmod} />
              <div style={{ marginTop: '0.5rem', display: 'flex' }}>
                $
                <HideSmall>
                  <TYPE.white
                    style={{
                      paddingRight: '.4rem'
                    }}
                  >
                    <CountUp
                      key={countUpValue}
                      isCounting
                      start={parseFloat(countUpValuePrevious)}
                      end={parseFloat(countUpValue)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.white>
                </HideSmall>
              </div>
            </AccountElement>
            {/* <Divider style={{ background: "#1f1f1f", height: "0.1px" }} /> */}
            <Divider />

            <StyledNavLink
              id={`swap-nav-link`}
              to={'/swap'}
              isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/swap')}
            >
              <Repeat size={14} />
              Swap
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/add/GLMR'}
              isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/add/GLMR')}
            >
              <Droplet size={14} />
              Liquidity
            </StyledNavLink>
            <StyledNavLink
              id={`stake-nav-link`}
              to={'/earn'}
              isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/earn')}
            >
              <CreditCard size={14} />
              Earn
            </StyledNavLink>
            <StyledNavLink
              id={`stake-nav-link`}
              to={'/coming-soon'}
              isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/coming-soon')}
            >
              <Aperture size={14} />
              NFT
            </StyledNavLink>
            <MenuItem id={`stake-nav-link`} href={'https://bridge.demodyfi.com'}>
              <Link size={14} />
              Bridge <sup>↗</sup>
            </MenuItem>
            <MenuItem id={`stake-nav-link`} href={'https://staking.demodyfi.com/'}>
              <Layers size={14} />
              Stake <sup>↗</sup>
            </MenuItem>
            <MenuItem id="link" target="_blank" href="https://demodyfi.com">
              <Info size={14} />
              About
            </MenuItem>
            <MenuItem id="link" target="_blank" href="https://docs.demodyfi.com">
              <BookOpen size={14} />
              Docs
            </MenuItem>
            <MenuItem id="link" target="_blank" href={'https://github.com/Demodyfi'}>
              <Code size={14} />
              Code
            </MenuItem>
            <MenuItem id="link" href="">
              <MessageCircle size={14} />
              Discord
            </MenuItem>
          </MenuFlyoutTablet>
        </>
      )}
    </StyledMenu>
  )
}
