import { Interface } from '@ethersproject/abi'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as STAKING_REWARDS_FACTORY_ABI } from '@uniswap/liquidity-staker/build/StakingRewardsFactory.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'

import YIELDFARM_ABI from './YieldFarm.json'

const STAKING_REWARDS_INTERFACE = new Interface(STAKING_REWARDS_ABI)

const STAKING_REWARDS_FACTORY_INTERFACE = new Interface(STAKING_REWARDS_FACTORY_ABI)

const YIELDFARM_INTERFACE = new Interface(YIELDFARM_ABI)
const IUniswapV2PairABI_INTERFACE = new Interface(IUniswapV2PairABI)

export {
  STAKING_REWARDS_FACTORY_INTERFACE,
  STAKING_REWARDS_INTERFACE,
  YIELDFARM_INTERFACE,
  IUniswapV2PairABI_INTERFACE
}
