import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'

const StyledSwapHeader = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: -4px;
  width: 100%;
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: rgb(255,255,255);
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <ActiveText>Swap</ActiveText>
        <Settings />
      </RowBetween>
    </StyledSwapHeader>
  )
}
