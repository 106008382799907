import QuestionHelper from 'components/QuestionHelper'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { ButtonLight, ButtonPrimary, ButtonText } from '../Button'
import { AutoColumn } from '../Column'
import { DataCard } from '../earn/styled'
import { Input as NumericalInput } from '../NumericalInput'

import Modal from '../Modal'
import { RowBetween, RowFixed } from '../Row'
import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { CurrencyAmount } from '@jessupwall02/sdk'
import { tryParseAmount } from 'state/swap/hooks'
import { Dots } from 'components/swap/styleds'
import { useTokenBalance } from 'state/wallet/hooks'
import { useStakingPoolContract, useYieldFarmContract } from 'hooks/useContract'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TrendingUp } from 'react-feather'
import Loader from 'components/Loader'
import rewardImg from '../../assets/images/reward.png'

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1.5rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`

const ContentWrapper = styled(AutoColumn)`
  width: 35rem;
`

const ModalUpper = styled(DataCard)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: transparent;
  border-radius: 0px;
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

const Container = styled.div<{ hideInput: boolean }>`
  width: 100%;
  border-radius: 22px;
  padding: 0px;
  background: transparent;

  /* Gray/18 */
  color: white;
  border: 1px solid #3b3c4e;
  &:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/ linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 22px;
    outline: 0;
    outline-offset: 0;
  }
  &:active {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/ linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 22px;
    outline: 0;
    outline-offset: 0;
  }
  &:target {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/ linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 22px;
    outline: 0;
    outline-offset: 0;
  }
  &:focus-visible {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/ linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 22px;
    outline: 0;
  }
  &:focus-within {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/ linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 22px;
    outline: 0;
  }
`

const LogoDiv = styled.div`
  width: 32px;
  height: 32px;
  background: #403957;
  border-radius: 100%;
  overflow: hidden;
  padding: 4px;
`

const TableLogo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const PoolHeading = styled.div`
  display: flex;
  align-items: center;
`

const APRBadge = styled.h5`
  border-radius: 36px;
  width: fit-content;
  padding: 0.5rem;
  color: rgba(17, 202, 190, 1);
  background: rgba(17, 202, 190, 0.1);
  margin-right: 1.5rem;
  position: absolute;
  right: 0;
`

const UserDetails = styled.span`
  background: rgba(30, 25, 45, 0.63);
  padding: 1.5rem 1rem;
  border-radius: 16px;
`

const GradientRow = styled(RowBetween)`
  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default function PoolModal({
  poolInfo,
  isOpen,
  onDismiss
}: {
  poolInfo: any
  isOpen: boolean
  onDismiss: () => void
}) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const [typedValue, setTypedValue] = useState<string>('0')
  const [parsedAmount, setParsedAmount] = useState<CurrencyAmount | undefined>()
  const [approval, approveCallback] = useApproveCallback(parsedAmount, poolInfo.staking)
  const [userAvailableReward, setUserAvailableReward] = useState(0)
  const [loadingUserRewards, setLoadingUserRewards] = useState(false)
  const [userEstimatedReward, setUserEstimatedReward] = useState<number>(0)

  const stakingContract = useStakingPoolContract(poolInfo.staking)
  const yieldFarmingContract = useYieldFarmContract(poolInfo.yieldFarm)
  const tokenBalance = useTokenBalance(account ?? undefined, poolInfo.token)
  const addTransaction = useTransactionAdder()

  // modal and loading
  const [{ showConfirm, stakeErrorMessage, attemptingTxn, txHash, pendingContent }, setTransactionState] = useState<{
    showConfirm: boolean
    attemptingTxn: boolean
    stakeErrorMessage: string | undefined
    txHash: string | undefined
    pendingContent: string | undefined
  }>({
    showConfirm: false,
    attemptingTxn: false,
    stakeErrorMessage: undefined,
    txHash: undefined,
    pendingContent: undefined
  })

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (typedValue: string) => {
      setTypedValue(typedValue)
      setParsedAmount(tryParseAmount(typedValue, poolInfo.token))
    },
    [poolInfo.token]
  )

  const handleDismissConfirmation = useCallback(() => {
    setTransactionState({ showConfirm: false, attemptingTxn, stakeErrorMessage, txHash, pendingContent: '' })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput('')
    }
  }, [attemptingTxn, stakeErrorMessage, txHash, onUserInput])

  const getUserData = useCallback(async () => {
    if (!account) {
      return
    }

    const userLastEpochIdHarvested = (await yieldFarmingContract?.userLastEpochIdHarvested()).toNumber() ?? 0

    const calcTotalAmountPerEpoch = (genesisEpochAmount: number, epochId: number, deprecationPerEpoch: number) => {
      if (epochId === 0) {
        return 0
      }

      const epochReward = genesisEpochAmount - epochId * deprecationPerEpoch

      return epochReward
    }

    const calculateUserEpochReward = async (epochId: number) => {
      if (epochId === 0) {
        return 0
      }

      if (!yieldFarmingContract) {
        return
      }

      const epochStake = await yieldFarmingContract?.getEpochStake(account, epochId)

      const poolSize = await yieldFarmingContract?.getPoolSize(epochId)

      const rewardForCurrentEpoch = calcTotalAmountPerEpoch(
        poolInfo.GENESIS_EPOCH_AMOUNT_DMOD,
        epochId,
        poolInfo.deprecationPerEpochDMOD
      )

      let potentialUserReward = 0

      if (poolSize > 0) {
        potentialUserReward = (epochStake / poolSize) * rewardForCurrentEpoch
      }

      return potentialUserReward
    }

    const estimatedUserReward = await calculateUserEpochReward(parseInt(poolInfo.currentEpoch))
    if (estimatedUserReward) {
      console.log('estimatedUserReward: ', estimatedUserReward)
      setUserEstimatedReward(estimatedUserReward)
    }

    let totalAvailableReward = 0

    setLoadingUserRewards(true)
    for (let i = userLastEpochIdHarvested + 1; i < parseInt(poolInfo.currentEpoch); i += 1) {
      console.log('userLastEpochIdHarvested: ', userLastEpochIdHarvested)
      const epochReward = await calculateUserEpochReward(i)

      totalAvailableReward = totalAvailableReward + (epochReward ?? 0)
    }
    setLoadingUserRewards(false)
    setUserAvailableReward(totalAvailableReward)
  }, [
    account,
    yieldFarmingContract,
    poolInfo.deprecationPerEpochDMOD,
    poolInfo.GENESIS_EPOCH_AMOUNT_DMOD,
    poolInfo.currentEpoch
  ])

  const onStake = () => {
    if (!parsedAmount || approval !== ApprovalState.APPROVED || !stakingContract) {
      return
    }
    onDismiss()
    setTransactionState({
      showConfirm: true,
      attemptingTxn: true,
      stakeErrorMessage: undefined,
      txHash: undefined,
      pendingContent: 'Staking Tokens to the Pool'
    })
    console.log('stakingContract', stakingContract)
    stakingContract
      .deposit(poolInfo.token.address, '0x' + parsedAmount.raw.toString(16))
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Stake Tokens`
        })
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: undefined,
          txHash: response.hash,
          pendingContent: ''
        })
      })
      .catch((error: any) => {
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: error.message,
          txHash: undefined,
          pendingContent: ''
        })
        console.log(error)
      })
  }

  const onWithdraw = () => {
    if (!parsedAmount || !stakingContract) {
      return
    }
    onDismiss()
    setTransactionState({
      showConfirm: true,
      attemptingTxn: true,
      stakeErrorMessage: undefined,
      txHash: undefined,
      pendingContent: 'Withdrawing Tokens from the Pool'
    })
    stakingContract
      .withdraw(poolInfo.token.address, '0x' + parsedAmount.raw.toString(16))
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Withdraw Tokens`
        })
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: undefined,
          txHash: response.hash,
          pendingContent: ''
        })
      })
      .catch((error: any) => {
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: error.message,
          txHash: undefined,
          pendingContent: ''
        })
        console.log(error)
      })
  }

  const onHarvest = () => {
    if (!userAvailableReward || userAvailableReward === 0 || !yieldFarmingContract) {
      return
    }
    onDismiss()
    setTransactionState({
      showConfirm: true,
      attemptingTxn: true,
      stakeErrorMessage: undefined,
      txHash: undefined,
      pendingContent: 'Harvesting Tokens from the Pool'
    })
    yieldFarmingContract
      .massHarvest()
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Harvest Tokens`
        })
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: undefined,
          txHash: response.hash,
          pendingContent: ''
        })
      })
      .catch((error: any) => {
        setTransactionState({
          attemptingTxn: false,
          showConfirm: true,
          stakeErrorMessage: error.message,
          txHash: undefined,
          pendingContent: ''
        })
        console.log(error)
      })
  }

  // const harvestAndStake = () => {
  //   if (!userAvailableReward || userAvailableReward === 0 || !yieldFarmingContract) {
  //     return
  //   }
  //   onDismiss()
  //   setTransactionState({
  //     showConfirm: true,
  //     attemptingTxn: true,
  //     stakeErrorMessage: undefined,
  //     txHash: undefined,
  //     pendingContent: 'Harvesting and Restaking Tokens to the Pool'
  //   })
  //   yieldFarmingContract
  //     .harvestAndStake()
  //     .then((response: TransactionResponse) => {
  //       addTransaction(response, {
  //         summary: `Restaking Tokens`
  //       })
  //       setTransactionState({
  //         attemptingTxn: false,
  //         showConfirm: true,
  //         stakeErrorMessage: undefined,
  //         txHash: response.hash,
  //         pendingContent: ''
  //       })
  //     })
  //     .catch((error: any) => {
  //       setTransactionState({
  //         attemptingTxn: false,
  //         showConfirm: true,
  //         stakeErrorMessage: error.message,
  //         txHash: undefined,
  //         pendingContent: ''
  //       })
  //       console.log(error)
  //     })
  // }

  const confirmationContent = useCallback(
    () =>
      stakeErrorMessage ? (
        <TransactionErrorContent onDismiss={handleDismissConfirmation} message={stakeErrorMessage} />
      ) : null,
    [stakeErrorMessage, handleDismissConfirmation]
  )

  useEffect(() => {
    getUserData()
  }, [getUserData])

  return (
    <>
      {poolInfo ? (
        <>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ''}
            content={confirmationContent}
            pendingText={pendingContent ? pendingContent : ''}
          />
          <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
            <ContentWrapper>
              <ModalUpper>
                <CardSection gap="md">
                  <PoolHeading style={{ display: 'flex' }}>
                    <LogoDiv>
                      <TableLogo src={poolInfo.logoOne} />
                    </LogoDiv>
                    <LogoDiv>
                      <TableLogo src={poolInfo.logoTwo} />
                     </LogoDiv>
                    <TYPE.white style={{ marginLeft: '0.5rem' }} fontWeight={500} fontSize={24}>
                      {poolInfo.name}
                    </TYPE.white>
                    <APRBadge>
                      APR {poolInfo.apr.toFixed(2)}% <TrendingUp size={16} />{' '}
                    </APRBadge>
                  </PoolHeading>
                </CardSection>
              </ModalUpper>
              <CardSection gap="md">
                {/* <RowBetween>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} fontWeight={400} color={theme.text2}>
                      Current Month
                    </TYPE.black>
                    <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                      {poolInfo.currentEpoch}
                    </TYPE.black>
                  </RowFixed>
                </RowBetween> */}
                <RowBetween>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} fontWeight={400} color={theme.text2}>
                      Total Rewards for Today
                    </TYPE.black>
                    <QuestionHelper text="Total allocation of DMOD tokens for the pool for today" />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                      {poolInfo.rewardForCurrentEpoch}
                    </TYPE.black>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} marginLeft={'4px'}>
                      DMOD
                    </TYPE.black>
                  </RowFixed>
                </RowBetween>
                <RowBetween>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} fontWeight={400} color={theme.text2}>
                      Total DMOD-LP Locked
                    </TYPE.black>
                    <QuestionHelper text="Total number of tokens locked in the pool" />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px', fontWeight: 700 }} fontSize={13}>
                      {poolInfo.poolBalanceCurrency.toFixed(6)}
                    </TYPE.black>
                    <TYPE.black style={{ letterSpacing: '0.5px', fontWeight: 700 }} fontSize={13} marginLeft={'4px'}>
                      DMOD-LP
                    </TYPE.black>
                  </RowFixed>
                </RowBetween>
                <RowBetween>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} fontWeight={400} color={theme.text2}>
                      Total DMOD-LP Locked Value
                    </TYPE.black>
                    <QuestionHelper text="Total value of tokens locked in the pool" />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px', fontWeight: 700 }} fontSize={13}>
                      {poolInfo.poolBalanceCurrencyValue.toFixed(2)}
                    </TYPE.black>
                    <TYPE.black style={{ letterSpacing: '0.5px', fontWeight: 700 }} fontSize={13} marginLeft={'4px'}>
                      $
                    </TYPE.black>
                  </RowFixed>
                </RowBetween>
                {/* <RowBetween>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} fontWeight={400} color={theme.text2}>
                      Pool Active Time Period
                    </TYPE.black>
                    <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
                  </RowFixed>
                  <RowFixed>
                    <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                      {poolInfo.NR_OF_EPOCH} Hours
                    </TYPE.black>
                  </RowFixed>
                </RowBetween> */}
                {account ? (
                  <>
                    <UserDetails>
                      <AutoColumn gap="md">
                        <RowBetween>
                          <RowFixed>
                            <TYPE.black
                              style={{ letterSpacing: '0.5px' }}
                              fontSize={13}
                              fontWeight={400}
                              color={theme.text2}
                            >
                              Your <span style={{ fontWeight: 'bold' }}> Stake</span> Balance
                            </TYPE.black>
                            <QuestionHelper text="Your staked balance in the pool" />
                          </RowFixed>
                          <RowFixed>
                            <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                              {poolInfo.epochStakeNextCurrency?.toFixed(6)}
                            </TYPE.black>
                            <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} marginLeft={'4px'}>
                              DMOD-LP
                            </TYPE.black>
                          </RowFixed>
                        </RowBetween>
                        <RowBetween>
                          <RowFixed>
                            <TYPE.black
                              style={{ letterSpacing: '0.5px' }}
                              fontSize={13}
                              fontWeight={400}
                              color={theme.text2}
                            >
                              Your Est. Reward for Today
                            </TYPE.black>
                            <QuestionHelper text="An estimation of your reward for today." />
                          </RowFixed>
                          <RowFixed>
                            <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                              {/* {poolInfo.rewardForCurrentEpoch.toFixed(2, {
                                decimalSeparator: '.',
                                groupSeparator: ',',
                                groupSize: 3,
                                fractionGroupSeparator: ' ',
                                fractionGroupSize: 5
                              })} */}
                              {userEstimatedReward.toFixed(3)}
                            </TYPE.black>
                            <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13} marginLeft={'4px'}>
                              DMOD
                            </TYPE.black>
                          </RowFixed>
                        </RowBetween>
                        <GradientRow
                          style={{ color: 'linear-gradient(73.6deg, #85FFC4 2.11%, #5CC6FF 42.39%, #BC85FF 85.72%)' }}
                        >
                          <RowFixed>
                            <TYPE.black
                              style={{ letterSpacing: '0.5px', fontWeight: 'bold' }}
                              fontSize={13}
                              color={theme.text2}
                            >
                              Total DMOD Reward Balance
                            </TYPE.black>
                            <QuestionHelper text="Total DMOD rewards collected till now" />
                          </RowFixed>
                          {loadingUserRewards ? (
                            <Loader style={{ textAlign: 'center' }} />
                          ) : (
                            <RowFixed>
                              <TYPE.black style={{ letterSpacing: '0.5px' }} fontSize={13}>
                                {/* {userAvailableReward.toFixed(2, {
                                  decimalSeparator: '.',
                                  groupSeparator: ',',
                                  groupSize: 3,
                                  fractionGroupSeparator: ' ',
                                  fractionGroupSize: 5
                                })} */}
                                {userAvailableReward.toString()}
                              </TYPE.black>
                              <TYPE.black
                                style={{ letterSpacing: '0.5px', display: 'flex', alignItems: 'center' }}
                                fontSize={13}
                                marginLeft={'4px'}
                              >
                                DMOD
                                <img src={rewardImg} alt="reward" style={{ marginLeft: '0.2rem' }} />
                              </TYPE.black>
                            </RowFixed>
                          )}
                        </GradientRow>
                      </AutoColumn>
                    </UserDetails>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <ButtonLight
                        disabled={userAvailableReward === 0}
                        style={{ marginRight: '0.5rem' }}
                        onClick={onHarvest}
                      >
                        <ButtonText>Harvest</ButtonText>
                      </ButtonLight>
                      {/* <ButtonLight
                        disabled={userAvailableReward === 0}
                        style={{ marginRight: '0.5rem' }}
                        onClick={harvestAndStake}
                      >
                        <ButtonText>Harvest + Stake</ButtonText>
                      </ButtonLight> */}
                    </div>
                  </>
                ) : null}

                <Container hideInput={true}>
                  <InputRow selected={true}>
                    <NumericalInput
                      className="token-amount-input"
                      value={typedValue}
                      onUserInput={val => {
                        onUserInput(val)
                      }}
                    />
                    {/* {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
                )} */}
                    <StyledBalanceMax
                      onClick={() => {
                        onUserInput(tokenBalance ? tokenBalance?.toFixed(8) : '0')
                      }}
                    >
                      MAX
                    </StyledBalanceMax>
                  </InputRow>
                </Container>

                {approval !== ApprovalState.APPROVED && parseFloat(typedValue) && parseFloat(typedValue) !== 0 ? (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <ButtonLight
                      style={{ marginRight: '0.5rem' }}
                      onClick={() => {
                        approveCallback()
                      }}
                      disabled={approval === ApprovalState.PENDING || !account}
                    >
                      <ButtonText>{approval === ApprovalState.PENDING ? <Dots>Approving</Dots> : 'Approve'}</ButtonText>
                    </ButtonLight>
                  </div>
                ) : null}

                <div style={{ display: 'flex', width: '100%' }}>
                  <ButtonLight
                    // disabled={!isAddress(account ?? '')}
                    disabled={!parseFloat(typedValue) || parseFloat(typedValue) <= 0 || !account}
                    padding="16px 16px"
                    width="100%"
                    borderRadius="12px"
                    mt="1rem"
                    onClick={onWithdraw}
                    marginTop="1rem"
                    marginRight="0.5rem"
                  >
                    Withdraw
                  </ButtonLight>
                  <ButtonPrimary
                    disabled={!parseFloat(typedValue) || parseFloat(typedValue) <= 0 || !account}
                    padding="16px 16px"
                    width="100%"
                    borderRadius="12px"
                    mt="1rem"
                    onClick={onStake}
                    marginTop="1rem"
                    marginRight="0.5rem"
                  >
                    Deposit
                  </ButtonPrimary>
                </div>
              </CardSection>
            </ContentWrapper>
          </Modal>
        </>
      ) : null}
    </>
  )
}
