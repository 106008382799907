import { JSBI, TokenAmount } from '@jessupwall02/sdk'
import { useMemo } from 'react'
import { BIG_INT_ONE, BIG_INT_ZERO } from '../../constants'
import { IUniswapV2PairABI_INTERFACE, YIELDFARM_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData } from '../multicall/hooks'
// // import { useStakingContract, useYieldFarmContract } from 'hooks/useContract'
// import dmod from '../../assets/images/dmodround.png'
// import moonbeam from '../../assets/images/moonbeam.png'
import { STAKING_CONTRACTS_INFO } from './useStakingPoolsInfo'

import { useEffect, useState } from 'react'

// export const STAKING_GENESIS = 1600387200

// export const REWARDS_DURATION_DAYS = 60

// // TODO add staking rewards addresses here
// export const STAKING_REWARDS_INFO: {
//   [chainId in ChainId]?: {
//     tokens: [Token, Token]
//     stakingRewardAddress: string
//   }[]
// } = {
//   [ChainId.MAINNET]: [
//     {
//       tokens: [WETH[ChainId.MAINNET], DAI],
//       stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711'
//     },
//     {
//       tokens: [WETH[ChainId.MAINNET], USDC],
//       stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b'
//     }
//     // {
//     //   tokens: [WETH[ChainId.MAINNET], USDT],
//     //   stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a'
//     // },
//     // {
//     //   tokens: [WETH[ChainId.MAINNET], WBTC],
//     //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e'
//     // }
//   ],
//   [ChainId.BSCTEST]: [
//     {
//       tokens: [WETH[ChainId.MAINNET], DAI],
//       stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711'
//     },
//     {
//       tokens: [WETH[ChainId.MAINNET], USDC],
//       stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b'
//     }
//     // {
//     //   tokens: [WETH[ChainId.MAINNET], USDT],
//     //   stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a'
//     // },
//     // {
//     //   tokens: [WETH[ChainId.MAINNET], WBTC],
//     //   stakingRewardAddress: '0xCA35e32e7926b96A9988f61d510E038108d8068e'
//     // }
//   ]
// }

// // TODO add staking rewards addresses here
// export const STAKING_CONTRACTS_INFO: {
//   [chainId in ChainId]?: {
//     staking: string
//     communityVault: string
//     farms: {
//       name: string
//       token: Token
//       tokenA: Token
//       tokenB: Token
//       yieldFarm: string
//       GENESIS_EPOCH_AMOUNT_DMOD: number
//       deprecationPerEpochDMOD: number
//       logoOne: any
//       logoTwo: any
//     }[]
//   }[]
// } = {
//   [ChainId.BSCTEST]: [
//     {
//       staking: '0x97070941e0685F19217f23b877160ea16ca9A81B',
//       communityVault: '0xA35159c961efA2B787B289160f07ecB459684230',
//       farms: [
//         {
//           name: 'DMOD-GLMR',
//           token: DMOD,
//           tokenA: DMOD,
//           tokenB: WETH[97],
//           yieldFarm: '0x03E9e25bA87c736aDCdec2f763b824396E364946',
//           GENESIS_EPOCH_AMOUNT_DMOD: 20000,
//           deprecationPerEpochDMOD: 1,
//           logoOne: dmod,
//           logoTwo: moonbeam
//         },
//         {
//           name: 'DMOD-GLMR',
//           token: DMOD,
//           tokenA: DMOD,
//           tokenB: WETH[97],
//           yieldFarm: '0x03E9e25bA87c736aDCdec2f763b824396E364946',
//           GENESIS_EPOCH_AMOUNT_DMOD: 20000,
//           deprecationPerEpochDMOD: 1,
//           logoOne: dmod,
//           logoTwo: moonbeam
//         }
//         // {
//         //   token: DMODETHLP,
//         //   yieldFarm: '0xde3843D3d9009039790575F34A29acC6eC535642',
//         //   GENESIS_EPOCH_AMOUNT_DMOD: 20000,
//         //   deprecationPerEpochDMOD: 125
//         // }
//       ]
//     }
//   ]
// }

// export interface StakingInfo {
//   // the address of the reward contract
//   stakingRewardAddress: string
//   // the tokens involved in this pair
//   tokens: [Token, Token]
//   // the amount of token currently staked, or undefined if no account
//   stakedAmount: TokenAmount
//   // the amount of reward token earned by the active account, or undefined if no account
//   earnedAmount: TokenAmount
//   // the total amount of token staked in the contract
//   totalStakedAmount: TokenAmount
//   // the amount of token distributed per second to all LPs, constant
//   totalRewardRate: TokenAmount
//   // the current amount of token distributed to the active account per second.
//   // equivalent to percent of total supply * reward rate
//   rewardRate: TokenAmount
//   // when the period ends
//   periodFinish: Date | undefined
//   // if pool is active
//   active: boolean
//   // calculates a hypothetical amount of token distributed to the active account per second.
//   getHypotheticalRewardRate: (
//     stakedAmount: TokenAmount,
//     totalStakedAmount: TokenAmount,
//     totalRewardRate: TokenAmount
//   ) => TokenAmount
// }

function fetchPrice(id: string): Promise<any> {
  return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`, {
    headers: {
      'cache-control': 'max-age=30,public,must-revalidate,s-maxage=60',
      'content-type': 'application/json; charset=utf-8 '
    }
  })
    .then(res => res.json())
    .catch(error => {})
}

export function usePrice(id: string): any {
  const [price, setPrice] = useState(0)
  useEffect(() => {
    fetchPrice(id).then(resp => {
      if (!resp) {
        return
      }
      setPrice(resp[id].usd)
    })
  }, [id])

  return price
}

export function useFarmTokenPrices(): any {}

// gets the staking info from the network for the active chain id
export function useAllPoolInfo(): any {
  const { chainId, account } = useActiveWeb3React()
  const glmrPrice = usePrice('moonbeam')

  const dmodPrice = usePrice('demodyfi')

  const usdtPrice = usePrice('tether')

  const usdcPrice = usePrice('usd-coin')

  // detect if staking is ended
  // const stakingContractInstance = useStakingContract()
  // const yieldFarmContractInstance = useYieldFarmContract()

  const info1 = useMemo(() => (chainId ? STAKING_CONTRACTS_INFO[chainId] ?? [] : []), [chainId])

  // // const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const yieldFarmAddresses = useMemo(() => info1[0]?.farms.map(({ yieldFarm }) => yieldFarm), [info1])

  const lpTokens = useMemo(() => info1[0]?.farms.map(({ token }) => token.address), [info1])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // // get all the info from the staking rewards contracts
  const currentEpochs = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getCurrentEpoch')

  const currentEpochsNext = useMemo(() => {
    return currentEpochs?.[0]?.result?.[0]
      ? JSBI.add(JSBI.BigInt(currentEpochs?.[0]?.result?.[0]), BIG_INT_ONE) ?? undefined
      : undefined
  }, [currentEpochs])

  const epochStakeNexts = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getEpochStake', [
    accountArg?.[0],
    currentEpochsNext?.toString()
  ])

  // const NR_OF_EPOCHS = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'NR_OF_EPOCHS')

  // const TOTAL_DISTRIBUTED_AMOUNTS = useMultipleContractSingleData(
  //   yieldFarmAddresses,
  //   YIELDFARM_INTERFACE,
  //   'TOTAL_DISTRIBUTED_AMOUNT'
  // )

  const poolBalances = useMultipleContractSingleData(yieldFarmAddresses, YIELDFARM_INTERFACE, 'getPoolSize', [
    currentEpochsNext?.toString()
  ])

  const reserves = useMultipleContractSingleData(lpTokens, IUniswapV2PairABI_INTERFACE, 'getReserves')

  const totalSupplies = useMultipleContractSingleData(lpTokens, IUniswapV2PairABI_INTERFACE, 'totalSupply')

  const data = useMemo(() => {
    if (!chainId) return

    return info1[0]?.farms.reduce<any[]>((memo, yieldFarm, index) => {
      // return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account

      // these get fetched regardless of account
      const currentEpoch = currentEpochs[index]

      const poolBalance = poolBalances[index]

      const reserve = reserves[index]

      const totalSupply = totalSupplies[index]

      const epochStakeNext = epochStakeNexts[index]

      if (
        // these may be undefined if not logged in
        currentEpoch &&
        !currentEpoch?.loading &&
        poolBalance &&
        !poolBalance?.loading &&
        reserve &&
        !reserve?.loading &&
        totalSupply &&
        !totalSupply?.loading &&
        !epochStakeNext?.loading
        // always need these
      ) {
        if (
          currentEpoch?.error ||
          poolBalance?.error ||
          reserve?.error ||
          totalSupply?.error ||
          epochStakeNext?.error
        ) {
          // console.error('Failed to load staking rewards info')
          return memo
        }

        const reserveA = new TokenAmount(yieldFarm.tokenA, reserve?.result?.[0])

        const reserveB = new TokenAmount(yieldFarm.tokenB, reserve?.result?.[1])

        const supplyAmount = new TokenAmount(yieldFarm.token, totalSupply?.result?.[0])

        const poolBalanceAmount = new TokenAmount(yieldFarm.token, poolBalance?.result?.[0] ?? BIG_INT_ZERO)

        const epochStakeNextAmount = new TokenAmount(yieldFarm.token, epochStakeNext?.result?.[0] ?? BIG_INT_ZERO)

        let tokenAPrice

        if (yieldFarm.tokenA.symbol === 'USDT') {
          tokenAPrice = usdtPrice
        } else if (yieldFarm.tokenA.symbol === 'USDC') {
          tokenAPrice = usdcPrice
        } else {
          tokenAPrice = glmrPrice
        }

        let tokenBPrice = dmodPrice

        const lpPrice =
          (parseFloat(reserveA.toFixed(6)) * tokenAPrice + parseFloat(reserveB.toFixed(6)) * tokenBPrice) /
          parseFloat(supplyAmount.toFixed(6))

        const poolValueLocked = parseFloat(poolBalanceAmount.toFixed(6)) * lpPrice
        const userValueLocked = parseFloat(epochStakeNextAmount.toFixed(6)) * lpPrice

        memo.push({ poolValueLocked, userValueLocked, lpPrice, dmodPrice })
      }
      return memo
    }, [])
  }, [
    info1,
    chainId,
    reserves,
    currentEpochs,
    totalSupplies,
    poolBalances,
    epochStakeNexts,
    glmrPrice,
    dmodPrice,
    usdtPrice,
    usdcPrice
  ])

  let totalValueLocked = 0
  let totalUserValueLocked = 0

  data?.forEach(({ poolValueLocked, userValueLocked }) => {
    totalValueLocked += poolValueLocked
    totalUserValueLocked += userValueLocked
  })

  return { totalValueLocked, totalUserValueLocked }
}
