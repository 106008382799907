import React from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect'

const EarnMain = styled.div`
  width: 100%;
  border: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const CenterDiv = styled.div`
  text-align: center;
  justify-content: center;
  width: 15rem;
`

const MainHeading = styled.div`
  font-size: 38px;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-family: Chillax-medium !important;
  font-weight: 400;
  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
font-size: 30px;
width: 50%;
`};
`

export default function Soon() {
  return (
    <EarnMain>
      <div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <CenterDiv>
            <MainHeading>
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .typeString('COMING SOON...')
                    .pauseFor(50)
                    .start()
                }}
              />
            </MainHeading>
          </CenterDiv>
        </div>
      </div>
    </EarnMain>
  )
}
