import React from 'react'
import styled from 'styled-components'
import { faGithub, faTwitter, faTelegram, faMedium, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dmod from '../../assets/images/dmod.png'
import { Activity } from 'react-feather'
import AnalyticsModal from 'components/AnalyticsModal'

const FooterDiv = styled.div`
  background-color: transparent;
  height: 3rem;
  color: white;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-top: 1px solid;
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    #ffffff 50%,
    rgba(255, 255, 255, 0.08) 100%
  );
  border-image-slice: 1; //
  ${({ theme }) => theme.mediaWidth.upToMedium`
// display: none;
// `};
`

const FooterIcon = styled.div`
  color: #999999;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
  background: rgba(48, 41, 72, 0.46);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  &:hover {
    color: #fff;
  }
`

const FooterRow = styled.div`
  display: flex;

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  // margin-left: 12rem;
  // `};
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  // margin-left: 0rem;
  // `};
`
const FooterFirstRow = styled.div`
  display: flex;
`
const FooterText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  border-right: 0.5px solid rgba(166, 160, 187, 0.4);
  padding-right: 0.8rem;
  color: #878dad;
  font-family: 'Chillax-Medium' !important;
  height: 1.2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 8px;
`};
`
const FooterTextTwo = styled.a`
  font-style: normal;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #878dad;
  font-family: 'Chillax-Medium' !important;
  cursor: pointer;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 8px;
  margin-left: 0.5rem;
  `};
  &:hover {
    color: #7d8fd0;
  }
`
const FooterLogo = styled.img`
  width: 16px;
  height: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 16px;
  height: 16px;
`}
`
const FooterFontIcon = styled(FontAwesomeIcon)`
  margin: 0.43rem;
  color: #696694;
`
function Footer() {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <FooterDiv>
      <AnalyticsModal isOpen={isOpen} onDismiss={() => setIsOpen(false)} />
      <FooterRow style={{ padding: '1rem 0rem', flexGrow: 1 }}>
        <FooterLogo src={dmod} />
        <FooterText style={{ display: 'flex', lineHeight: '16px', marginLeft: '0.5rem' }}>
          © 2022 All Rights Reserved
        </FooterText>{' '}
        <FooterTextTwo href="https://bit.ly/31mO29W" target="_blank">
          Terms<span style={{ color: ' #302948', marginLeft: '0.5rem' }}> • </span>{' '}
        </FooterTextTwo>
        <FooterTextTwo href="https://drive.google.com/file/d/12Gkui0Q9ArmXde6T5MdxkuVTq2NWjQCF/view" target="_blank">
          Privacy<span style={{ color: ' #302948', marginLeft: '0.5rem' }}> • </span>{' '}
        </FooterTextTwo>
        <FooterTextTwo href="https://demodyfi.com" target="_blank">
          About
        </FooterTextTwo>
      </FooterRow>
      <FooterFirstRow onClick={() => setIsOpen(true)} style={{ padding: '1rem 0rem', cursor: 'pointer' }}>
        <Activity size="20" style={{ color: '#A6A0BB', height: '1rem' }} />
        <FooterText style={{ display: 'flex', lineHeight: '16px', marginRight: '0.5rem', marginLeft: '0.5rem' }}>
          Analytics
        </FooterText>
      </FooterFirstRow>

      <FooterRow style={{ padding: '0.6rem 0rem' }}>
        <FooterIcon
          style={{ marginLeft: '0.5rem' }}
          onClick={e => window.open('https://github.com/DemodyFI', '_blank', 'noopener noreferrer')}
        >
          <FooterFontIcon icon={faGithub} />
        </FooterIcon>
        <FooterIcon
          onClick={e => window.open('https://www.linkedin.com/company/demodyfi/', '_blank', 'noopener noreferrer')}
        >
          <FooterFontIcon icon={faLinkedin} />
        </FooterIcon>
        <FooterIcon onClick={e => window.open('https://twitter.com/DemodyFi', '_blank', 'noopener noreferrer')}>
          <FooterFontIcon icon={faTwitter} />
        </FooterIcon>
        <FooterIcon onClick={e => window.open('https://t.me/DemodyFI', '_blank', 'noopener noreferrer')}>
          <FooterFontIcon icon={faTelegram} />
        </FooterIcon>
        <FooterIcon onClick={e => window.open('https://demodyfi.medium.com/', '_blank', 'noopener noreferrer')}>
          <FooterFontIcon icon={faMedium} />
        </FooterIcon>
      </FooterRow>
    </FooterDiv>
  )
}
export default Footer
