import { ChainId } from '@jessupwall02/sdk'
import React, { useCallback } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Logo from '../../assets/images/dmod.svg'
import { useActiveWeb3React } from '../../hooks'
// import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { ExternalLink } from '../../theme'

// import { LightGreyCard } from '../Card'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
// import ClaimModal from '../claim/ClaimModal'
// import { useShowClaimPopup } from '../../state/application/hooks'
// import { useUserHasAvailableClaim } from '../../state/claim/hooks'
// import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
// import { Dots } from '../swap/styleds'
// import Modal from '../Modal'
// import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import dmod from '../../assets/images/dmod2.svg'
import moonbeam from '../../assets/images/moonbeamheader.png'
import external from '../../assets/images/external.svg'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DMODToken } from '../../constants'
import { usePrice } from 'state/stake/useAllPoolInfo'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 2rem 4rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   display: flex;
    // width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 72px;
    background-color: transparent ;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > * {
    margin-left: 1rem;
  }

  // /* addresses safari's lack of support for "gap" */
  // & > * {
  //   margin-left: 8px;
  //   margin-right: 1rem;
  // }

  ${({ theme }) => theme.mediaWidth.upToSmall`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  width: auto;
  margin-left: 2rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  display: none;
 `};
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//   padding: 1rem 1rem 1rem 1rem;
//   justify-content: center;
//   background: rgba(20, 51, 77, 0.54);
//   position: fixed;
//   bottom: 0;
//   border-radius: 42px;
//   width: 35rem;
//   padding: 1rem 3rem;
// `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0px;
  background-color: rgba(106, 121, 159, 0.18);
  border-radius: 100px;
  color: white;
  height: 2.5rem;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'pnum' on, 'lnum' on;
  background: linear-gradient(73.6deg, rgb(133, 255, 196) 2.11%, rgb(92, 198, 255) 42.39%, rgb(188, 133, 255) 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
font-size: 10px;
`};
`

const NetworkLabelText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #53cbc8;
  font-feature-settings: 'pnum' on, 'lnum' on;
  // background: linear-gradient(73.6deg, rgb(133, 255, 196) 2.11%, rgb(92, 198, 255) 42.39%, rgb(188, 133, 255) 85.72%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  margin-top: 0.45rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 10px;
  `};
`

const UniSpan = styled.div`
  background: rgba(106, 121, 159, 0.18);
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 100px;
  font-weight: 100;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 5rem;
  height: 2.5rem;
  padding: 1px 16px 1px 8px;
  cursor: pointer;
  // margin-right: 2rem;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`
const DmodUNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `};
`
const HideSmall = styled.span``

// const NetworkCard = styled(LightGreyCard)`
//   border-radius: 12px;
//   padding: 8px 12px;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin: 0;
//     margin-right: 1rem;
//     width: initial;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     flex-shrink: 1;
//   `};
// `

const BalanceText = styled(Text)`
  font-weight: 600;
  ont-family: 'poppins'
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div``

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  font-family: Chillax-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 87% */
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #A6A0BB;
  margin-left: 1.5rem;
  opacity: 0.9;
  text-decoration-line: none;
  backdrop-filter: blur(4px);
  padding-left: 0.6rem;
    padding-right: 0.6rem;
  &:hover {
    background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.${activeClassName} {
    background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 13.5px;
    border-bottom: 1.2px solid;
    border-image: linear-gradient(
73.6deg
,#91dcf0 6.19%,#25b0d7 25.05%,#fa8ffa 57.68%);
    border-image-slice: 1;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    line-height: 1.8rem;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-left: 1.5rem;
`};
  // ${({ theme }) => theme.mediaWidth.upToMedium`
  // font-size: 18px;
  // margin-left: 2rem;
  // &.${activeClassName} {
  //   background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
  //   animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  //   animation-duration: 2.5s;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   background-color: #081E31;
  //   padding: 1rem 3rem;
  //   border-radius: 2rem;
   
  // }

  // `};
  // ${({ theme }) => theme.mediaWidth.upToLarge`
  // margin-left: 1rem;
  // `};
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  font-family: Chillax-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 87% */
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #A6A0BB;
  margin-left: 1.5rem;
  opacity: 0.9;
  text-decoration-line: none;
  backdrop-filter: blur(4px);
  padding-left: 0.6rem;
    padding-right: 0.6rem;
  &:hover {
    background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.${activeClassName} {
    background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2.5s;
    -webkit-background-clip: text;
    font-size: 13.5px;
    -webkit-text-fill-color: transparent;
    border-bottom: 1.2px solid;
    border-image: linear-gradient(
73.6deg
,#91dcf0 6.19%,#25b0d7 25.05%,#fa8ffa 57.68%);
    border-image-slice: 1;
    line-height: 1.8rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-left: 1.5rem;
`};
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  // font-size: 18px;
  // margin-left: 2rem;
  // `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 1rem;
  `};
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: rgb(255, 255, 255);
  }
`

const DmodImg = styled.img`
  width: 1.8rem;
  height: auto;
  margin-right: 0.4rem;
`

const MoonbeamImg = styled.img`
  width: 1.8rem;
  height: 1.9rem;
  margin-right: 0.4rem;
  margin-top: 0.3rem;
`
const GrayButton = styled.span`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
display: none;
`};
`
const ExternalImg = styled.img`
  margin-left: 0.2rem;
`
const MainLogo = styled.img`
  width: 10rem;
  height: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
width: 8rem;
    height: auto;
`};
`
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Moonbeam',
  [ChainId.BSCTEST]: 'BscTest'
}

export default function Header() {
  const { chainId, account } = useActiveWeb3React()
  const { t } = useTranslation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const glmrPrice = usePrice('moonbeam')

  const dmodPrice = usePrice('demodyfi')
  // const [isDark] = useDarkModeManager()
  // const [darkMode, toggleDarkMode] = useDarkModeManager()

  // const toggleClaimModal = useToggleSelfClaimModal()

  // const availableClaim: boolean = useUserHasAvailableClaim(account)

  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()
  // const dmodBalance: TokenAmount | undefined = useDMODBalance()

  // const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  // const showClaimPopup = useShowClaimPopup()

  const countUpValue = dmodPrice?.toFixed(3) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  const switchNetwork = useCallback(async () => {
    if (window.ethereum) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x504',
              chainName: 'Moonbeam Network',
              nativeCurrency: {
                name: 'Glimmer Token',
                symbol: 'GLMR',
                decimals: 18
              },
              rpcUrls: ['https://moonbeam.api.onfinality.io/public'],
              blockExplorerUrls: ['https://moonscan.io/']
            }
          ]
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  const addDmodAsset = useCallback(async () => {
    if (window.ethereum) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: DMODToken?.[chainId ?? ChainId.MAINNET]?.address, // The address that the token is at.
              symbol: 'DMOD', // A ticker symbol or shorthand, up to 5 chars.
              decimals: 18, // The number of decimals in the token
              image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9713.png' // A string url of the token logo
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [chainId])

  return (
    <HeaderFrame>
      {/* <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}
      <HeaderRow>
        <Title href=".">
          <UniIcon>
            <MainLogo src={Logo} alt="logo" />
          </UniIcon>
        </Title>
        <HeaderLinks>
          <StyledNavLink
            style={{ marginLeft: '1rem' }}
            id={`swap-nav-link`}
            to={'/swap'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/swap')}
          >
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/add/GLMR'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/add/GLMR')}
          >
            {t('Liquidity')}
          </StyledNavLink>
          <StyledNavLink
            id={`stake-nav-link`}
            to={'/earn'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/earn')}
          >
            Earn
          </StyledNavLink>
          <StyledNavLink
            id={`stake-nav-link`}
            to={'/coming-soon'}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/coming-soon')}
          >
            NFT
          </StyledNavLink>
          {/* <StyledNavLink id={`stake-nav-link`} to={'/vote'}>
            Vote
          <StyledExternalLink id={`stake-nav-link`} href={'https://uniswap.info'}>
            Charts <span style={{ fontSize: '11px' }}>↗</span>
          </StyledExternalLink> */}
          <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.demodyfi.com'}>
            Bridge <ExternalImg src={external} />
            {/* <span style={{ fontSize: '11px' }}>↗</span> */}
          </StyledExternalLink>
          <StyledExternalLink id={`stake-nav-link`} href={'https://staking.demodyfi.com/'}>
            Stake <ExternalImg src={external} />
          </StyledExternalLink>
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          {chainId && NETWORK_LABELS[chainId] && (
            <UNIWrapper onClick={switchNetwork}>
              <UniSpan style={{ display: 'flex' }}>
                <MoonbeamImg src={moonbeam} />
                {/* <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  {NETWORK_LABELS[chainId]}
                </UNIAmount> */}
                <NetworkLabelText>${glmrPrice}</NetworkLabelText>
                {/* <NetworkLabelText>{NETWORK_LABELS[chainId]}</NetworkLabelText> */}
              </UniSpan>

              <CardNoise />
            </UNIWrapper>
          )}
          {/* {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <ButtonText>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming UNI</Dots> : 'Claim UNI'}
                </TYPE.white>
                </ButtonText>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}

          <DmodUNIWrapper onClick={addDmodAsset}>
            <UniSpan>
              <UNIAmount active={true} style={{ pointerEvents: 'auto' }}>
                <DmodImg src={dmod} />

                <HideSmall>
                  <NetworkLabelText
                    style={{
                      paddingRight: '.4rem'
                    }}
                  >
                    $
                    <CountUp
                      key={countUpValue}
                      isCounting
                      start={parseFloat(countUpValuePrevious)}
                      end={parseFloat(countUpValue)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </NetworkLabelText>
                </HideSmall>
              </UNIAmount>
            </UniSpan>

            <CardNoise />
          </DmodUNIWrapper>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <GrayButton>
                <FontAwesomeIcon
                  color="#53F3C3"
                  style={{ width: '0.5rem', marginLeft: '1rem', marginTop: '1px' }}
                  icon={faCircle}
                />
                <BalanceText style={{ flexShrink: 0, fontSize: '12.5px' }} pl="0.4rem" pr="0.5rem" fontWeight={600}>
                  {userEthBalance?.toSignificant(4)} GLMR
                </BalanceText>
              </GrayButton>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Menu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
