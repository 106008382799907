import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  border-radius: 30px;
  // min-width: 575px;
  // max-width: 645px;
  width: 45%;
  background: rgba(54, 54, 67, 0.23);
  padding: 1rem;
  margin: 0rem;
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  // width: 70%;
  margin: 3rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 5rem 0rem;
  width: 100%;
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
