// import React from 'react'
// import { AutoColumn } from '../../components/Column'
// import styled from 'styled-components'
// import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
// import { TYPE, ExternalLink } from '../../theme'
// import PoolCard from '../../components/earn/PoolCard'
// import { RowBetween } from '../../components/Row'
// import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
// import Loader from '../../components/Loader'
// import { useActiveWeb3React } from '../../hooks'
// // import { JSBI } from '@jessupwall02/sdk'
// // import { BIG_INT_ZERO } from '../../constants'
// import { OutlineCard } from '../../components/Card'

// const PageWrapper = styled(AutoColumn)`
//   max-width: 640px;
//   width: 100%;
// `

// const TopSection = styled(AutoColumn)`
//   max-width: 720px;
//   width: 100%;
// `

// const PoolSection = styled.div`
//   display: grid;
//   grid-template-columns: 1fr;
//   column-gap: 10px;
//   row-gap: 15px;
//   width: 100%;
//   justify-self: center;
// `

// const DataRow = styled(RowBetween)`
//   ${({ theme }) => theme.mediaWidth.upToSmall`
// flex-direction: column;
// `};
// `

// export default function Earn() {
//   const { chainId } = useActiveWeb3React()

//   // staking info for connected account
//   const stakingInfos = useStakingInfo()

//   /**
//    * only show staking cards with balance
//    * @todo only account for this if rewards are inactive
//    */
//   // const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))

//   // toggle copy if rewards are inactive
//   const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

//   return (
//     <PageWrapper gap="lg" justify="center">
//       <TopSection gap="md">
//         <DataCard>
//           <CardBGImage />
//           <CardNoise />
//           <CardSection>
//             <AutoColumn gap="md">
//               <RowBetween>
//                 <TYPE.white fontWeight={600}>Uniswap liquidity mining</TYPE.white>
//               </RowBetween>
//               <RowBetween>
//                 <TYPE.white fontSize={14}>
//                   Deposit your Liquidity Provider tokens to receive UNI, the Uniswap protocol governance token.
//                 </TYPE.white>
//               </RowBetween>{' '}
//               <ExternalLink
//                 style={{ color: 'white', textDecoration: 'underline' }}
//                 href="https://uniswap.org/blog/uni/"
//                 target="_blank"
//               >
//                 <TYPE.white fontSize={14}>Read more about UNI</TYPE.white>
//               </ExternalLink>
//             </AutoColumn>
//           </CardSection>
//           <CardBGImage />
//           <CardNoise />
//         </DataCard>
//       </TopSection>

//       <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
//         <DataRow style={{ alignItems: 'baseline' }}>
//           <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating pools</TYPE.mediumHeader>
//           <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} />
//         </DataRow>

//         <PoolSection>
//           {stakingRewardsExist && stakingInfos?.length === 0 ? (
//             <Loader style={{ margin: 'auto' }} />
//           ) : !stakingRewardsExist ? (
//             <OutlineCard>No active pools</OutlineCard>
//           ) : stakingInfos?.length !== 0 && stakingInfos.length === 0 ? (
//             <OutlineCard>No active pools</OutlineCard>
//           ) : (
//             stakingInfos?.map(stakingInfo => {
//               // need to sort by added liquidity here
//               return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
//             })
//           )}
//         </PoolSection>
//       </AutoColumn>
//     </PageWrapper>
//   )
// }

import React from 'react'
// import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import remove from '../../assets/images/remove.png'
import styled from 'styled-components'
import PoolModal from 'components/PoolModal'
// import { useActiveWeb3React } from 'hooks'
import Loader from 'components/Loader'
import { OutlineCard } from 'components/Card'
import PoolRow from 'components/earn/PoolRow'
import { useStakingPoolsInfo } from 'state/stake/useStakingPoolsInfo'
import { Link } from 'react-router-dom'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { TYPE } from '../../theme'
import { Text } from 'rebass'
import { useAllPoolInfo } from 'state/stake/useAllPoolInfo'

const EarnMain = styled.div`
  width: 100%;
  border: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const CenterDiv = styled.div`
  text-align: center;
  justify-content: center;
  width: 30rem;
`
const CenterHeading = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  /* or 48px */
  margin: 0;
  text-align: center;

  /* Base/Grey 0 */

  color: #ffffff;
  animation: flipInX; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
  margin-bottom: 2rem;
`
// const CenterSubHeading = styled.h5`
//   font-family: Poppins;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 32px;
//   /* or 229% */

//   text-align: center;

//   color: #a6a0bb;
//   animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
//   animation-duration: 2.2s;
// `
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`
// const EarnButton = styled.button`
//   background: linear-gradient(318.86deg, #563bd3 0%, rgba(123, 35, 195, 0) 53.86%),
//     radial-gradient(83.68% 83.68% at 0% 0%, #6fe38f 0%, rgba(133, 66, 244, 0.2) 95.83%);
//   border: 0px solid hsla(0, 0%, 100%, 0.4);
//   box-sizing: border-box;
//   border-radius: 25px;
//   font-weight: 100;
//   font-family: 'Chillax-Medium' !important;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 14px;
//   margin-right: 2rem;
//   text-align: center;
//   text-transform: capitalize;
//   color: #fff;
//   -webkit-backdrop-filter: blur(4px);
//   backdrop-filter: blur(4px);
//   min-width: 7rem;
//   height: 2.5rem;
//   padding: 0.4rem 1.3rem;
//   margin-top: 0.7rem;

//   cursor: pointer;
//   &:hover {
//     animation: pulse; /* referring directly to the animation's @keyframe declaration */
//     animation-duration: 1s;
//   }
// `
const EarnButtonTwo = styled.button`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: 'Chillax-Medium' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 0.7rem;

  cursor: pointer;
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`
const ConnectButtonText = styled.h5`
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
  font-size: 14px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const PoolDiv = styled.div`
  background: rgba(13, 13, 30, 0.8);
  margin-top: 2rem;
  padding: 4rem 7rem;
  margin-right: -5rem;
  margin-left: -5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 2rem 1.5rem;
`};
`
const TableDiv = styled.div`
  background: rgba(27, 26, 46, 0.4);
  border-radius: 12px;
  padding: 2rem 3rem;
  margin-top: 2rem;
  height: fit-content;
  // overflow-y: scroll;
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.8s;
  animate-delay: 0.5s;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 2rem 1rem;
`};
`
const HoldingDiv = styled.div`
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 24px;
  margin-top: 2rem;
  height: fit-content;
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.8s;
  animate-delay: 0.5s;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 2rem 1rem;
`};
`

// const SortButton = styled.select`
//   border: 0px;
//   background: #3b3c4e;
//   border-radius: 100px;
//   color: white;
//   padding: 0.5rem 1.5rem 0.5rem 1.2rem;
//   height: 3rem;
//   cursor: pointer;
//   font-family: Poppins;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 24px;
//   /* identical to box height, or 171% */

//   display: flex;
//   align-items: center;
//   font-feature-settings: 'pnum' on, 'lnum' on;

//   color: #a6a0bb;
// `

const Table = styled.table`
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`
const TableHeading = styled.th`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #a6a0bb;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 9px;
   padding-left: 1rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
   font-size: 9px;
   padding-left: 1rem;
  `};
`
const TableRow = styled.tr`
  cursor: pointer;
`

const FirstButton = styled.button`
  background: #111025;
  border: 0;

  padding: 0rem 3rem;
  border-radius: 100px;

  height: 3rem;
  margin-right: 2rem;
  cursor: pointer;
`

const FirstButtonAlt = styled.button`
  background: #1c1924;
  border: 0;

  padding: 0rem 3rem;
  border-radius: 100px;

  height: 3rem;
  margin-right: 2rem;
  cursor: pointer;
`

// const SecondButton = styled.button`
//   background: #3b3c4e;
//   border: 0;

//   padding: 0rem 3rem;
//   border-radius: 100px;

//   height: 3rem;
//   margin-right: 3rem;
//   color: white;
//   cursor: pointer;
// `

// const RemoveImg = styled.img``

const TradeButton = styled.button`
  background: linear-gradient(318.86deg, #563bd3 0%, rgba(123, 35, 195, 0) 53.86%),
    radial-gradient(102.68% 108.68% at 0% 0%, #6fe38f 0%, rgba(133, 66, 244, 0.2) 95.83%);
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.8rem 1.3rem;
  margin-top: 0.7rem;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    linear-gradient(318.86deg,#563bd30%,rgba(123,35,195,0)53.86%),radial-gradient(102.68%108.68%at0%0%,#6fe38f0%,rgba(133,66,244,0.2)95.83%)animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`

const HoldingHeading = styled(TYPE.black)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
  /* identical to box height, or 214% */
  font-family: Poppins;

  font-feature-settings: 'salt' on, 'liga' off;

  color: #a6a0bb;
`

const HoldingText = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 154% */

  font-feature-settings: 'salt' on, 'liga' off;

  color: #ffffff;
`
const PurpleDiv = styled.div`
  position: absolute;
  width: 1030px;
  height: 500px;
  left: 208px;
  top: -23px;

  background: radial-gradient(40.45% 40.45% at 50% 46.16%, rgba(85, 57, 255, 0.154) 3.12%, rgba(255, 57, 240, 0) 100%);
  filter: blur(24px);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
 `};
`
const HoldingHr = styled.hr`
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  height: 4rem;
  width: 1px;
`
export default function Earn() {
  const [step] = React.useState<number>(0)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [poolInfo, setPoolInfo] = React.useState<any>(undefined)
  const [selectedButton, setSelectedButton] = React.useState<number>(0)

  // const { chainId } = useActiveWeb3React()

  // staking info for connected account
  const stakingInfos = useStakingPoolsInfo()
  const { totalValueLocked, totalUserValueLocked } = useAllPoolInfo()

  return (
    <EarnMain>
      {/* <Particles /> */}

      <PurpleDiv />
      {step === 0 ? (
        <div>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <CenterDiv>
              <CenterHeading>Leverage assets you believe in.</CenterHeading>
              {/* <CenterSubHeading>
                With us, you can maximize your yield, and create unparalleled opportunities to make the most of DeFi.
              </CenterSubHeading> */}
              <ButtonDiv>
                <EarnButtonTwo>
                  <ConnectButtonText as={Link} to="/create/GLMR" style={{ margin: 0 }}>
                    create a Pair
                  </ConnectButtonText>
                </EarnButtonTwo>
                <TradeButton id="join-pool-button" as={Link} to="/add/GLMR">
                  Add Liquidity
                </TradeButton>{' '}
              </ButtonDiv>
              <HoldingDiv style={{ marginBottom: '2rem' }}>
                <AutoRow justify="space-around" gap="4px">
                  <AutoColumn style={{ justifyContent: 'flex-start', textAlign: 'left' }}>
                    <HoldingHeading>Total Value Locked</HoldingHeading>
                    <HoldingText pt={1}>$ {totalValueLocked.toFixed(2)}</HoldingText>
                  </AutoColumn>
                  <HoldingHr />
                  {/* <AutoColumn justify="center">
                    <TYPE.black style={{ fontSize: '16px' }}>Farms TVL</TYPE.black>
                    <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
                      $137,432,253
                    </Text>
                  </AutoColumn> */}
                  <AutoColumn style={{ justifyContent: 'flex-start', textAlign: 'left' }}>
                    <HoldingHeading>My Holdings</HoldingHeading>
                    <HoldingText pt={1}>$ {totalUserValueLocked.toFixed(2)}</HoldingText>
                  </AutoColumn>
                </AutoRow>
              </HoldingDiv>
            </CenterDiv>
          </div>
          <PoolDiv>
            <div style={{ display: 'flex' }}>
              {selectedButton === 0 ? (
                <FirstButtonAlt>
                  <ConnectButtonText style={{ margin: 0 }}>All Pools</ConnectButtonText>
                </FirstButtonAlt>
              ) : (
                <FirstButton onClick={() => setSelectedButton(0)}>
                  <ConnectButtonText style={{ margin: 0 }}>All Pools</ConnectButtonText>
                </FirstButton>
              )}

              {selectedButton === 1 ? (
                <FirstButtonAlt>
                  <ConnectButtonText style={{ margin: 0 }}>DMOD Pools</ConnectButtonText>
                </FirstButtonAlt>
              ) : (
                <FirstButton onClick={() => setSelectedButton(1)}>
                  <ConnectButtonText style={{ margin: 0 }}>DMOD Pools</ConnectButtonText>
                </FirstButton>
              )}

              {selectedButton === 2 ? (
                <FirstButtonAlt>
                  <ConnectButtonText style={{ margin: 0 }}>Stable Pools</ConnectButtonText>
                </FirstButtonAlt>
              ) : (
                // <FirstButton onClick={() => setSelectedButton(2)}>
                <FirstButton style={{ opacity: '0.4' }}>
                  <ConnectButtonText style={{ margin: 0 }}>Stable Pools</ConnectButtonText>
                </FirstButton>
              )}

              <div style={{ flexGrow: 1 }}> </div>
              {/* <SortButton>
                Highest Supply
                <FontAwesomeIcon style={{ width: '0.5rem', marginLeft: '1rem' }} icon={faArrowDown} />
                <option value="sort"> Highest Supply</option>
                <option value="filter">Option 2</option>
              </SortButton> */}
            </div>

            <TableDiv>
              {stakingInfos?.length === 0 ? (
                <Loader style={{ margin: 'auto', textAlign: 'center' }} />
              ) : // ) : !stakingRewardsExist ? (
              // <OutlineCard>No active pools</OutlineCard>
              stakingInfos?.length !== 0 && stakingInfos.length === 0 ? (
                <OutlineCard>No active pools</OutlineCard>
              ) : (
                <>
                  {poolInfo ? (
                    <PoolModal poolInfo={poolInfo} isOpen={isOpen} onDismiss={() => setIsOpen(false)} />
                  ) : null}
                  <Table style={{ width: '100%' }}>
                    <TableRow>
                      <TableHeading style={{ paddingLeft: '0rem' }}>Stake</TableHeading>
                      <TableHeading>Total Value Locked (TVL)</TableHeading>
                      {/* <TableHeading>Total Pool Rewards</TableHeading> */}
                      <TableHeading>Pool Rewards(24H)</TableHeading>
                      <TableHeading>APR</TableHeading>
                    </TableRow>
                    <>
                      {stakingInfos?.map(stakingPool => (
                        <PoolRow setPoolInfo={setPoolInfo} poolInfo={stakingPool} setIsOpen={setIsOpen} />
                      ))}
                    </>
                  </Table>
                </>
              )}
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <CircleDiv>1</CircleDiv>
              <CircleDiv>...</CircleDiv>
            </div> */}
            </TableDiv>
          </PoolDiv>
        </div>
      ) : null}

      {/* {step === 1 ? (
        <div>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <CenterDiv>
              <CenterHeading>Manage Liquidity, Earn DMODs</CenterHeading>
              <ButtonDiv>
                <EarnButtonTwo>
                  <ConnectButtonText style={{ margin: 0 }}>create a Pair</ConnectButtonText>
                </EarnButtonTwo>
                <EarnButton>Add Liquidity</EarnButton>{' '}
              </ButtonDiv>
            </CenterDiv>
          </div>
          <PoolDiv>
            <div style={{ display: 'flex' }}>
              <SecondButton onClick={() => setStep(0)} style={{ marginRight: '0rem' }}>
                All Pools
              </SecondButton>
              <FirstButton style={{ marginLeft: '-2rem', marginRight: '3rem' }}>
                <ConnectButtonText style={{ margin: 0 }}>My Pools</ConnectButtonText>
              </FirstButton>
              <div style={{ flexGrow: 1 }}> </div>
              <SortButton>
                Highest Supply
                <FontAwesomeIcon style={{ width: '0.5rem', marginLeft: '1rem' }} icon={faArrowDown} />
                <option value="sort"> Highest Supply</option>
                <option value="filter">Option 2</option>
              </SortButton>
            </div>
            <TableDiv>
              <Table>
                <TableRow>
                  <TableHeading>Name</TableHeading>
                  <TableHeading>Liquidity</TableHeading>
                  <TableHeading>Your Tokens</TableHeading>
                  <TableHeading>Pool Share</TableHeading>
                  <TableHeading>Total Income</TableHeading>
                </TableRow>
                {items.map(item => (
                  <TableRow>
                    <FirstTableData style={{ display: 'flex' }}>
                      <LogoDiv>
                        <TableLogo src={item.logoOne} />
                      </LogoDiv>
                      <LogoDiv>
                        <TableLogo src={item.logoTwo} />
                      </LogoDiv>
                      <NameSpan>{item.name}</NameSpan>
                    </FirstTableData>
                    <TableData>{item.liquidity}</TableData>
                    <TableData>{item.tokens}</TableData>
                    <TableData>{item.share}</TableData>
                    <TableData style={{ paddingRight: 0 }}>
                      {item.income}{' '}
                      <FirstButton
                        style={{
                          background: '#1E1D35',
                          padding: '0px 25px',
                          marginLeft: '2rem'
                        }}
                      >
                        <ConnectButtonText style={{ margin: 0 }}>Add</ConnectButtonText>
                      </FirstButton>
                      <span>
                        <FirstButton
                          style={{
                            background: '#1E1D35',
                            padding: '0px 15px',
                            marginLeft: '2.6rem'
                          }}
                        >
                          <RemoveImg src={remove} />
                        </FirstButton>
                      </span>
                    </TableData>
                  </TableRow>
                ))}
              </Table>
              //<div style={{ display: "flex", justifyContent: "center" }}>
              //<CircleDiv>1</CircleDiv>
              //<CircleDiv>...</CircleDiv>
            </div>
            </TableDiv>
          </PoolDiv>
        </div>
      ) : null} */}
    </EarnMain>
  )
}
