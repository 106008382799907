import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from '@jessupwall02/sdk'
import { Link } from 'react-router-dom'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import Typewriter from 'typewriter-effect'

import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { RowBetween } from '../../components/Row'
// import { ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
// import { DataCard } from '../../components/earn/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 3rem;
  animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`
const TradeButton = styled.button`
  background: linear-gradient(318.86deg, #563bd3 0%, rgba(123, 35, 195, 0) 53.86%),
    radial-gradient(102.68% 108.68% at 0% 0%, #6fe38f 0%, rgba(133, 66, 244, 0.2) 95.83%);
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.8rem 1.3rem;
  margin-top: 0.7rem;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    linear-gradient(318.86deg,#563bd30%,rgba(123,35,195,0)53.86%),radial-gradient(102.68%108.68%at0%0%,#6fe38f0%,rgba(133,66,244,0.2)95.83%)animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`
const TradeButtonTwo = styled.button`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: 'Chillax-Medium' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 0.7rem;

  cursor: pointer;
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`
const ConnectButtonText = styled.h5`
  background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);
  font-size: 14px;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const PageWrapper = styled.div`
  // max-width: 640px;
  // min-width: 575px;
  background: rgba(54, 54, 67, 0.23);
  width: 45%;
  padding: 1rem;
  border-radius: 30px;

  margin: 0rem;
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 70%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 3rem;
  width: 100%;
  `};
`

// const VoteCard = styled(DataCard)`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
//   overflow: hidden;
// `

// const TitleRow = styled(RowBetween)`
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     flex-wrap: wrap;
//     gap: 12px;
//     width: 100%;
//     flex-direction: column-reverse;
//   `};
// `

// const ButtonRow = styled(RowFixed)`
//   gap: 8px;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 100%;
//     flex-direction: row-reverse;

//   `};
// `

// const ResponsiveButtonPrimary = styled(ButtonPrimary)`
//   width: fit-content;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 48%;
//   `};
// `

// const ResponsiveButtonSecondary = styled(ButtonSecondary)`
//   width: fit-content;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 48%;
//   `};
// `

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MainHeading = styled.div`
  font-size: 38px;
  opacity: 0.7;
  width: 35rem;
  margin: 0rem;
  font-family: Chillax-medium !important;
  font-weight: 400;
  width: 55%;
  padding: 4rem 5rem 4rem 0rem;
  margin-bottom: 9rem;
  color: rgb(255, 255, 255);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 30px;
  width: 50%;
  margin: 0rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 30px;
  width: 80%;
  margin: 0rem;
  `};
  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin: 4rem 1rem 0rem 3rem;
  width: 100%;
  `};
`

const StyledPoolHeader = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: -4px;
  width: 100%;
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 25px;
  color: rgb(255, 255, 255);
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <MainHeading>
        <Typewriter
          onInit={typewriter => {
            typewriter
              .typeString(
                '<span style="color: #FF39F0;font-weight: 500"></div>Demodyfi</span> is leveraging the specific functionality of '
              )
              .pauseFor(50)
              .typeString('<span>Polkadot Parachains while reaching users and assets </span>')
              .pauseFor(50)
              .typeString(
                '<span>on other <span style="color: #19D2FF;font-weight: 500"></div>Blockchain Systems seamlessly.</span></span>'
              )
              .pauseFor(50)
              .start()
          }}
        />
      </MainHeading>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />

        {/* <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Liquidity provider rewards</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  {`Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.`}
                </TYPE.white>
              </RowBetween>
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://uniswap.org/docs/v2/core-concepts/pools/"
              >
                <TYPE.white fontSize={14}>Read more about providing liquidity</TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard> */}
        <StyledPoolHeader>
          <RowBetween>
            <ActiveText>Manage Pool</ActiveText>
            <ButtonDiv>
              <TradeButtonTwo style={{ marginTop: '0.5rem' }}>
                <ConnectButtonText as={Link} to="/create/GLMR" style={{ margin: 0 }}>
                  create a Pair
                </ConnectButtonText>
              </TradeButtonTwo>
              <TradeButton id="join-pool-button" as={Link} to="/add/GLMR" style={{ marginTop: '0.5rem' }}>
                Add Liquidity
              </TradeButton>{' '}
            </ButtonDiv>
          </RowBetween>
        </StyledPoolHeader>
        <AutoColumn gap="lg" justify="center" style={{ padding: '1rem' }}>
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            {/* <TitleRow padding={'0'}>
              <HideSmall>
                <TYPE.mediumHeader style={{ justifySelf: 'flex-start' }}>Your liquidity</TYPE.mediumHeader>
              </HideSmall>
              <ButtonDiv>
                <TradeButtonTwo>
                  <ConnectButtonText as={Link} to="/create/ETH" style={{ margin: 0 }}>
                    create a Pair
                  </ConnectButtonText>
                </TradeButtonTwo>
                <TradeButton id="join-pool-button" as={Link} to="/add/ETH">
                  Add Liquidity
                </TradeButton>{' '}
              </ButtonDiv>
               <ButtonRow>
                <ResponsiveButtonSecondary as={Link} padding="6px 8px" to="/create/ETH">
                  Create a pair
                </ResponsiveButtonSecondary>
                <ResponsiveButtonPrimary
                  id="join-pool-button"
                  as={Link}
                  padding="6px 8px"
                  borderRadius="12px"
                  to="/add/ETH"
                >
                  <Text fontWeight={500} fontSize={16}>
                    Add Liquidity
                  </Text>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow> */}

            {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </Card>
            ) : v2IsLoading ? (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </EmptyProposals>
            ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
              <>
                {/* <ButtonSecondary>
                  <RowBetween>
                    <ExternalLink href={'https://uniswap.info/account/' + account}>
                      Account analytics and accrued fees
                    </ExternalLink>
                    <span> ↗</span>
                  </RowBetween>
                </ButtonSecondary> */}
                {v2PairsWithoutStakedAmount.map(v2Pair => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
                {stakingPairs.map(
                  (stakingPair, i) =>
                    stakingPair[1] && ( // skip pairs that arent loaded
                      <FullPositionCard
                        key={stakingInfosWithBalance[i].stakingRewardAddress}
                        pair={stakingPair[1]}
                        stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                      />
                    )
                )}
              </>
            ) : (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  No liquidity found.
                </TYPE.body>
              </EmptyProposals>
            )}

            <AutoColumn justify={'center'} gap="md">
              <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0' }}>
                {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : "Don't see a pool you joined?"}{' '}
                <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                  {hasV1Liquidity ? 'Migrate now.' : 'Import it.'}
                </StyledInternalLink>
              </Text>
            </AutoColumn>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
