import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
// import QuestionHelper from '../QuestionHelper'
import Settings from '../Settings'
// import { useDispatch } from 'react-redux'
// import { AppDispatch } from 'state'
// import { resetMintState } from 'state/mint/actions'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 25px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

const TradeButtonTwo = styled.button`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: 'Chillax-Medium' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;

  cursor: pointer;
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`

const ManagePoolButtonText = styled.h5`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  background: linear-gradient(73.6deg, #91dcf0 6.19%, #25b0d7 25.05%, #fa8ffa 57.68%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '20px', display: 'none' }}>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <Settings />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding, creating }: { adding: boolean; creating: boolean }) {
  // reset states on back
  // const dispatch = useDispatch<AppDispatch>()

  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 1rem 1rem' }}>
        {/* <HistoryLink
          to="/pool"
          onClick={() => {
            adding && dispatch(resetMintState())
          }}
        >
          <StyledArrowLeft />
        </HistoryLink> */}
        <ActiveText style={{ flexGrow: 1 }}>
          {creating ? 'Create a pair' : adding ? 'Add Liquidity' : 'Remove Liquidity'}
        </ActiveText>
        <TradeButtonTwo>
          <ManagePoolButtonText as={HistoryLink} to="/pool" style={{ margin: 0 }}>
            Manage Pools
          </ManagePoolButtonText>
        </TradeButtonTwo>
        <Settings />
      </RowBetween>
    </Tabs>
  )
}
