import React from 'react'
// import { AlertCircle } from 'react-feather'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { TYPE } from '../../theme'
import { ExternalLink } from '../../theme/components'
import { getEtherscanLink } from '../../utils'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import Approve from '../../assets/images/Approve.png'
import Failed from '../../assets/images/Failed.png'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

const ApproveImg = styled.img`
  width: 28px;
  height: 24px;
`

const FailedImg = styled.img`
  width: 24px;
  height: 24px;
`
const ButtonSpan = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'pnum' on, 'lnum' on;
  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default function TransactionPopup({
  hash,
  success,
  summary
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  // const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>{success ? <ApproveImg src={Approve} /> : <FailedImg src={Failed} />}</div>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</TYPE.body>
        {chainId && (
          <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
            <ButtonSpan> View on Moonscan</ButtonSpan>
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
