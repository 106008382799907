import React from 'react'

import styled from 'styled-components'
import { TableRow } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const FirstTableData = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* White */

  color: #ffffff;
`

const LogoDiv = styled.div`
  width: 32px;
  height: 32px;
  background: #403957;
  border-radius: 100%;
  overflow: hidden;
  padding: 4px;
`

const TableLogo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
`
const NameSpan = styled.div`
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 9px;
  `};
`

const FontAwesomeIconSvg = styled(FontAwesomeIcon)`
  width: 0.5rem;
  margin-left: 7rem;
  color: #a6a0bb;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 1rem;
  `};
`

const TableData = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #ffffff;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 9px;
   padding-left: 1rem;
  `};
`

const PoolRowTable = styled(TableRow)`
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`

export default function PoolRow({
  setPoolInfo,
  poolInfo,
  setIsOpen
}: {
  setPoolInfo: any
  poolInfo: any
  setIsOpen: any
}) {
  return (
    <PoolRowTable
      onClick={() => {
        setPoolInfo(poolInfo)
        setIsOpen(true)
      }}
    >
      <FirstTableData style={{ display: 'flex' }}>
        <LogoDiv>
          <TableLogo src={poolInfo.logoOne} />
        </LogoDiv>
        <LogoDiv>
          <TableLogo src={poolInfo.logoTwo} />
        </LogoDiv>
        <NameSpan>{poolInfo.name}</NameSpan>
      </FirstTableData>
      {/* <TableData>
        {poolInfo.poolBalanceCurrency.toFixed(6)} ({poolInfo.poolBalanceCurrencyValue.toFixed(2)} $) DMOD-LP
      </TableData> */}
      <TableData style={{ fontWeight: 'bold' }}>{poolInfo.poolBalanceCurrencyValue.toFixed(2)} $</TableData>
      {/* <TableData>
        {poolInfo.TOTAL_DISTRIBUTED_AMOUNT_CURRENCY.toFixed(2, {
          decimalSeparator: '.',
          groupSeparator: ',',
          groupSize: 3,
          fractionGroupSeparator: ' ',
          fractionGroupSize: 5
        })}{' '}
        DMOD
      </TableData> */}
      <TableData>{poolInfo.rewardForCurrentEpoch} DMOD</TableData>
      <TableData style={{ color: '#11CABE', paddingRight: 0, fontWeight: 'bold' }}>
        {poolInfo.apr.toFixed(2)} {' %'}
        <FontAwesomeIconSvg icon={faChevronRight} />
      </TableData>
    </PoolRowTable>
  )
}
